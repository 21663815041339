interface MenuIconProps {
  className?: string;
}

const MenuIcon: React.FC<MenuIconProps> = (props) => {
  return (
    <>
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={props.className}
      >
        <rect x="4" y="9" width="24" height="2" fill="currentColor" />
        <rect x="4" y="15" width="24" height="2" fill="currentColor" />
        <rect x="4" y="21" width="24" height="2" fill="currentColor" />
      </svg>
    </>
  );
};

export default MenuIcon;
