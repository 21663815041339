import FilterOffIcon from 'components/icons/FilterOffIcon';
import FilterOnIcon from 'components/icons/FilterOnIcon';
import gsap from 'gsap';
import React, { useEffect, useRef } from 'react';
import classnames from 'utils/classnames';

interface CartPurchaseTypeOptionProps {
  className?: string;
  theme: 'dark' | 'light';
  purchaseTypeId: number;
  activePurchaseTypeId?: number;
  onClick: (id: number) => void;
  children?: React.ReactNode;
}

const CartPurchaseTypeOption: React.FC<CartPurchaseTypeOptionProps> = (props) => {
  const { children, theme, activePurchaseTypeId, purchaseTypeId, onClick } = props;

  const iconOnRef = useRef(null);

  useEffect(() => {
    const iconOn = iconOnRef.current;

    if (purchaseTypeId === activePurchaseTypeId) {
      gsap.to(iconOn, { opacity: 1, scale: 1, duration: 0.3, ease: 'power3.out' });
    } else {
      gsap.to(iconOn, { opacity: 0, scale: 0, duration: 0.3, ease: 'power3.out' });
    }

    return () => {
      gsap.killTweensOf(iconOn);
    };
  }, [activePurchaseTypeId]);

  return (
    <>
      <button
        className={classnames('relative flex h-full w-full rounded-md border text-left', {
          'border-sf-blue-700 text-sf-blue-700': theme === 'dark',
          'border-white bg-sf-blue-700 text-white': theme === 'light',
        })}
        onClick={() => {
          onClick(purchaseTypeId);
        }}
      >
        <div className="px-4 py-6">
          <div className="relative flex w-full items-center justify-center gap-x-3">
            <div className="relative">
              <div className="relative">
                <FilterOffIcon />
              </div>
              <div ref={iconOnRef} className="gpu absolute inset-0 scale-0 transform opacity-0">
                <FilterOnIcon />
              </div>
            </div>
            <div className="w-full">
              <div className="text-sm font-medium">{children}</div>
            </div>
            <div></div>
          </div>
        </div>
      </button>
    </>
  );
};

export default CartPurchaseTypeOption;
