import { SFCart } from 'lib/sanford-api';
import React from 'react';
import CartItem from './CartItem';

interface CartItemListProps {
  cartResults: SFCart;
  children?: React.ReactNode;
}

const CartItemList: React.FC<CartItemListProps> = (props) => {
  const { cartResults } = props;

  return (
    <>
      <div className="relative w-full py-6 text-center ">
        <p className="text-sm">Items to purchase ({cartResults.lineItems.length})</p>
      </div>
      <div>
        {cartResults?.lineItems.map((item) => (
          <CartItem key={item.id} product={item} cartResults={cartResults} />
        ))}
        {props.children}
      </div>
    </>
  );
};

export default CartItemList;
