import React, { useEffect, useRef } from 'react';
import gsap from 'gsap';
import { useRouter } from 'next/router';
import { NavigationItemData } from 'lib/contentful/types';

interface NavigationTopMenuItemProps {
  href: string;
  item?: NavigationItemData;
  useVerticalBar?: boolean;
  onMouseEnter?: (navigationItem: NavigationItemData) => void;
  onMouseLeave?: (navigationItem: NavigationItemData) => void;
  children?: React.ReactNode;
}

const NavigationTopMenuItem: React.FC<NavigationTopMenuItemProps> = (props) => {
  const { href, item } = props;

  const containerRef = useRef(null);
  const underlineRef = useRef(null);
  const triggerRef = useRef(null);

  const router = useRouter();
  const isItemCurrentPage = router.asPath === href;

  const onTriggerMouseEnter = () => {
    if (props.onMouseEnter) {
      props.onMouseEnter(item);
    }
  };

  const onContainerMouseLeave = () => {
    if (props.onMouseLeave) {
      props.onMouseLeave(item);
    }
  };

  useEffect(() => {
    const container = containerRef.current;
    const underline = underlineRef.current;
    const trigger = triggerRef.current;

    const offsetX = 300;

    if (isItemCurrentPage) {
      gsap.fromTo(
        underline,
        { x: `-${offsetX}%`, opacity: 0 },
        { x: '0%', opacity: 1, duration: 0.6, delay: 0.3, ease: 'power3.out' }
      );
    } else {
      gsap.to(underline, {
        x: `${offsetX}%`,
        opacity: 0,
        duration: 0.6,
        delay: 0,
        ease: 'power3.inOut',
      });
    }

    const onMouseEnter = () => {
      if (!isItemCurrentPage) {
        gsap.fromTo(
          underline,
          { x: `-${offsetX}%`, opacity: 0 },
          { x: '0%', opacity: 1, duration: 0.6, delay: 0, ease: 'power3.out' }
        );
      }
    };

    const onMouseLeave = () => {
      if (!isItemCurrentPage) {
        gsap.to(underline, {
          x: `${offsetX}%`,
          opacity: 0,
          duration: 0.6,
          delay: 0,
          ease: 'power3.out',
        });
      }
    };

    trigger.addEventListener('mouseenter', onMouseEnter);
    container.addEventListener('mouseleave', onMouseLeave);

    return () => {
      trigger.removeEventListener('mouseenter', onMouseEnter);
      container.removeEventListener('mouseleave', onMouseLeave);
    };
  }, [isItemCurrentPage]);

  return (
    <>
      {props.useVerticalBar && (
        <li>
          <div className="justify-self-end">
            <div className="relative mb-1 h-[24px] w-[1px] bg-sf-blue-700"></div>
          </div>
        </li>
      )}
      <li
        ref={containerRef}
        className="relative flex h-full items-center justify-center"
        onMouseLeave={onContainerMouseLeave}
      >
        <div className="relative">
          <div ref={triggerRef} className="relative pb-1.5" onMouseEnter={onTriggerMouseEnter}>
            {props.children}
          </div>
          <div className="pointer-events-none absolute inset-0">
            <div
              ref={underlineRef}
              className="absolute bottom-0 left-1/2 h-[2px] w-[17px] -translate-x-1/2 transform bg-sf-blue-700 opacity-0"
            ></div>
          </div>
        </div>
      </li>
    </>
  );
};

export default NavigationTopMenuItem;
