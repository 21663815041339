import sanfordApi from 'lib/sanford-api';
import { QUERIES } from 'queries';
import { useQuery } from 'react-query';

export const useCustomer = () => {
  const customer = useQuery(QUERIES.CUSTOMER, sanfordApi.getCustomer, {
    staleTime: 1000 * 3600,
  });

  return {
    loading: customer.isLoading || customer.isFetching,
    error: customer.error,
    customer: customer?.data?.data?.result,
  };
};
