import { ChangeEvent } from 'react';
import classnames from 'utils/classnames';
import React from 'react';
import CheckboxIcon from 'components/icons/CheckboxIcon';

interface CheckboxProps {
  id: string;
  name: string;
  text: string;
  error?: string;
  checked: boolean;
  onBlur: (input: ChangeEvent<HTMLInputElement>) => any;
  onChange: (input: ChangeEvent<HTMLInputElement>) => any;
  children?: React.ReactNode;
}

const CheckBox: React.FC<CheckboxProps> = (props) => {
  const classes = classnames('relative flex items-center justify-center', {
    'text-rose-600': !!props.error,
  });

  return (
    <div className="flex flex-col gap-2">
      <div className=" text-sf-blue-700">
        <label className="flex cursor-pointer items-center gap-2">
          <div className={classes}>
            <input
              className={'peer sr-only appearance-none'}
              id={props.id}
              name={props.name}
              onBlur={props.onBlur}
              onChange={props.onChange}
              type="checkbox"
            />
            <div className="  bock h-5 w-5 flex-none  rounded border-2 border-current"></div>
            <CheckboxIcon className="absolute m-auto opacity-0 peer-checked:opacity-100" />
          </div>

          <div className="form-checkbox-label inline-block">
            <div className="relative select-none text-sm">{props.text}</div>
          </div>
        </label>
        {props.children}
      </div>
      {props.error && <span className="text-xs text-rose-600">{props.error}</span>}
    </div>
  );
};

export default CheckBox;
