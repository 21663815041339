import ReOrderIcon from 'components/icons/ReOrderIcon';

interface SubscriptionBadgeProps {
  type: `normal` | `small`;
}

const SubscriptionBadge: React.FC<SubscriptionBadgeProps> = (props) => {
  const { type } = props;
  return (
    <div className="relative flex flex-nowrap items-center justify-center gap-x-1 rounded-full bg-white px-3 py-2 text-xs font-medium">
      <div>
        <ReOrderIcon />
      </div>
      {type === `normal` && (
        <>
          <div>Subscription available</div>
        </>
      )}
      {type === `small` && (
        <>
          <div>Subscription</div>
        </>
      )}
    </div>
  );
};

export default SubscriptionBadge;
