import { useRouter } from 'next/router';

const PreviewBanner: React.FC = (props) => {
  const router = useRouter();
  return (
    <>
      <div className="relative left-0 top-0 flex w-full items-center justify-center bg-sf-gold-500 p-4">
        <div className="flex flex-1 items-center justify-center">
          <span className="justify-self-center text-white">PREVIEW MODE ENABLED</span>
        </div>

        <a
          onClick={() => router.push('/api/exit-preview')}
          className="mr-2   cursor-pointer  text-white underline"
        >
          Close
        </a>
      </div>
    </>
  );
};

export default PreviewBanner;
