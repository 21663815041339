import React, { useRef } from 'react';
import { useRouter } from 'next/router';
import MenuItemArrowRight from 'components/icons/MenuItemArrowRightIcon';
import classnames from 'utils/classnames';
import { NavigationItemData } from 'lib/contentful/types';
import MenuItemArrowLeft from 'components/icons/MenuItemArrowLeftIcon';

interface NavigationSideMenuItemProps {
  href: string;
  currentItem?: NavigationItemData;
  useAsBackButton?: boolean;
  useAsSecondaryLink?: boolean;
  onClick?: () => void;
  onForward?: (activeSubItems: NavigationItemData[]) => void;
  onBack?: () => void;
  children?: React.ReactNode;
}

const NavigationSideMenuItem: React.FC<NavigationSideMenuItemProps> = (props) => {
  const { useAsBackButton, useAsSecondaryLink, currentItem, href } = props;
  const containerRef = useRef(null);

  const router = useRouter();
  const isItemCurrentPage = router.asPath === href;

  const useSubItems = currentItem?.subItems?.length > 0 || false;

  return (
    <>
      <li ref={containerRef} className="relative">
        <div className="relative">
          <div className="relative flex w-full flex-nowrap items-stretch justify-between">
            <div
              className={classnames('w-full text-sm uppercase', {
                'font-medium': !useAsSecondaryLink,
              })}
            >
              <div className="flex w-full items-stretch justify-center">
                {useAsBackButton && (
                  <button
                    className="pl-6 pr-4"
                    onClick={() => {
                      props.onBack();
                    }}
                  >
                    <MenuItemArrowLeft />
                  </button>
                )}
                <div
                  className={classnames('w-full py-5', {
                    'pl-6': !useAsBackButton,
                  })}
                >
                  {props.children}
                </div>
              </div>
            </div>

            {useSubItems && (
              <button
                className="px-6"
                onClick={() => {
                  const currentSubItens: NavigationItemData[] = [currentItem].concat(
                    currentItem.subItems
                  ) as NavigationItemData[];
                  props.onForward(currentSubItens);
                }}
              >
                <div>
                  <MenuItemArrowRight />
                </div>
              </button>
            )}
          </div>
        </div>
        <div className="pointer-events-none absolute bottom-0 left-0 top-0">
          <div
            className={classnames(
              'transfor absolute bottom-0 left-0 top-1/2 h-3/4 w-[5px] -translate-y-1/2 bg-sf-blue-700',
              {
                'opacity-0': !isItemCurrentPage || useAsBackButton,
              }
            )}
          ></div>
        </div>
      </li>
    </>
  );
};

export default NavigationSideMenuItem;
