import { CART_MIN_ORDER_VALUE } from 'config/business.config';
import { SFCart } from 'lib/sanford-api';

/**
 * Whether a cart can proceed to checkout
 * @param cart
 */
interface CheckoutValidation {
  canCheckout: boolean;
  checkoutError?: 'CART_INVALID' | 'CART_MIN_ORDER_VALUE';
}

export const checkoutValidation: (cart: SFCart) => CheckoutValidation = (cart) => {
  if (!cart || !cart.lineItems || !cart.purchaseTypeId) {
    return {
      canCheckout: false,
      checkoutError: 'CART_INVALID',
    };
  }

  if (cart.price < CART_MIN_ORDER_VALUE) {
    return {
      canCheckout: false,
      checkoutError: 'CART_MIN_ORDER_VALUE',
    };
  }

  return {
    canCheckout: true,
  };
};
