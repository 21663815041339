import React, { useEffect } from 'react';
import NextLink from 'next/link';
import { useMutation } from 'react-query';

import classnames from 'utils/classnames';
import { FillLinkButton } from 'components/Button';
import EmptyCartIcon from 'components/icons/EmptyCartIcon';
import { useCart } from 'hooks/useCart';
import ModalCart from './ModalCart';
import CartItemList from './CartItemList';
import { useCartStore } from 'store/cartStore';
import CartPreloader from './CartPreloader';
import CartPurchaseType from './CartPurchaseType';
import InfoNote from '../ui/InfoNote';
import { numberToCurrency } from 'utils/currency';
import { checkoutValidation } from 'utils/checkout';
import HorizontalLine from 'components/ui/HorizontalLine';
import sanfordApi, { SanfordAPIErrors } from 'lib/sanford-api';
import frontendConfig from 'config/frontend-config';
import Router, { useRouter } from 'next/router';
import { getLoginRouteWithRedirect } from 'utils/route';

const Cart: React.FC = () => {
  const router = useRouter();
  const { loading, cart } = useCart();
  const { cartOpen, toggleCart } = useCartStore();
  const { canCheckout, checkoutError } = checkoutValidation(cart);

  const checkoutMutation = useMutation(() => sanfordApi.checkout(), {
    onMutate: async (response) => {},

    onError: (err, variables, context) => {
      const apiResponse = (err as any)?.response?.data;
      if (apiResponse.error.code === SanfordAPIErrors.Unautheticated) {
        Router.push(getLoginRouteWithRedirect(apiResponse?.result?.redirectUrl));
      } else {
        alert('Sorry, something went wrong, please try again.');
      }
    },

    onSuccess: (response) => {
      window.location.href = response.data.result.checkoutUrl;
    },
    onSettled: () => {},
  });

  const handleCheckout = () => checkoutMutation.mutate();

  useEffect(() => {
    if (router.query.cart === 'edit') {
      toggleCart(true);
    }
  }, [router.query.cart]);

  const onClose = () => {
    toggleCart(false);
    if (router.query.cart === 'edit') {
      const { cart: cartQuery, ...routerQuery } = router.query;
      router.replace(
        {
          query: { ...routerQuery },
        },
        undefined,
        { shallow: true }
      );
    }
  };

  return (
    <>
      {cartOpen && (
        <>
          <ModalCart onClose={onClose}>
            <div className="relative h-full w-full">
              {!cart?.lineItems?.length && (
                <div className="relative flex h-full w-full items-center justify-center">
                  <div className="relative flex flex-col items-center justify-center px-6 text-center">
                    <div className="pb-4">
                      <EmptyCartIcon />
                    </div>
                    <div className="pb-7 md:pb-9">
                      <p className="text-center text-xs font-medium">
                        You have no items in your cart.
                      </p>
                    </div>
                    <div>
                      <FillLinkButton buttonSize={'none'}>
                        <NextLink href={frontendConfig.shopNowUrlSlug} passHref>
                          <a target={'_self'} className="w-full px-8 py-4 text-center uppercase">
                            Shop now
                          </a>
                        </NextLink>
                      </FillLinkButton>
                    </div>
                  </div>
                </div>
              )}

              {cart?.lineItems?.length && (
                <>
                  <div className="relative h-full w-full">
                    <div className="block-scrollbar absolute inset-0 overflow-y-auto overflow-x-hidden px-6">
                      <CartPurchaseType purchaseTypeId={cart.purchaseTypeId} />
                      <CartItemList cartResults={cart}>
                        <div className="relative w-full">
                          <div className="relative w-full">
                            <HorizontalLine />
                            <div className="relative w-full py-10 text-center text-base">
                              <div className="relative w-full py-3">
                                <span className="font-medium">Total:</span>{' '}
                                {numberToCurrency(cart.price, cart.currency)}
                              </div>
                              <InfoNote>
                                <span
                                  className={classnames({
                                    'text-red-500': checkoutError === 'CART_MIN_ORDER_VALUE',
                                  })}
                                >
                                  A minimum order value of $30 applies for all online orders.
                                </span>
                              </InfoNote>
                            </div>
                          </div>
                        </div>
                      </CartItemList>
                      <div className="pointer-events-auto relative flex w-full items-center justify-center pb-10 opacity-100">
                        <FillLinkButton
                          onClick={() => handleCheckout()}
                          className="w-full text-center"
                          buttonSize="none"
                          disabled={!canCheckout || checkoutMutation.isLoading}
                        >
                          <span className="w-full px-8 py-4 text-center uppercase">
                            Continue to checkout
                          </span>
                        </FillLinkButton>
                      </div>
                    </div>
                  </div>
                </>
              )}

              <CartPreloader isLoading={loading} />
            </div>
          </ModalCart>
        </>
      )}
    </>
  );
};

export default Cart;
