import React, { useEffect } from 'react';
import Script from 'next/script';
import { useZendeskStore } from 'store/zendeskStore';
import frontendConfig from 'config/frontend-config';

const ZendeskWidget: React.FC = () => {
  const zendesk = useZendeskStore();
  const key = frontendConfig.zendeskKey;

  useEffect(() => {
    if (typeof window === 'undefined') return;

    (window as any).zESettings = {
      webWidget: {
        position: {
          horizontal: 'left',
          vertical: 'bottom',
        },
        offset: {
          horizontal: '10px',
          vertical: '10px',
        },
        color: {
          theme: '#172A4F',
          header: '#172A4F',
        },
        navigation: {
          popoutButton: {
            enabled: false,
          },
        },
      },
    };
  }, []);

  useEffect(() => {
    if (typeof window === 'undefined' || !zendesk.isReady) return;

    if (zendesk.isOpen) {
      (window as any).zE('webWidget', 'open');
    } else {
      (window as any).zE('webWidget', 'close');
    }
  }, [zendesk.isOpen]);

  if (!key) return <></>;

  return (
    <Script
      id="ze-snippet"
      src={`https://static.zdassets.com/ekr/snippet.js?key=${key}`}
      strategy="lazyOnload"
      onLoad={() => {
        (window as any).zE('webWidget', 'hide');
        (window as any).zE('webWidget:on', 'close', function () {
          (window as any).zE('webWidget', 'hide');
          zendesk.setOpen(false);
        });
        (window as any).zE('webWidget:on', 'open', function () {
          (window as any).zE('webWidget', 'show');
          zendesk.setOpen(true);
        });
        zendesk.setReady();
      }}
    />
  );
};

export default ZendeskWidget;
