import { useQuery } from 'react-query';
import sanfordApi from 'lib/sanford-api';
import { QUERIES } from 'queries';

export const useCart = () => {
  const cart = useQuery(QUERIES.CART, sanfordApi.getCart);

  return {
    loading: cart.isLoading || cart.isFetching,
    error: cart.error,
    cart: cart.data?.data?.result,
  };
};
