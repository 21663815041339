interface EmptyCartIconProps {
  className?: string;
}

const EmptyCartIcon: React.FC<EmptyCartIconProps> = (props) => {
  return (
    <>
      <svg
        width="38"
        height="33"
        viewBox="0 0 38 33"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={props.className}
      >
        <path
          d="M33.6785 30.128L36.6119 12.528C36.815 11.309 35.875 10.1992 34.6391 10.1992H3.36092C2.12504 10.1992 1.18496 11.309 1.38813 12.528L4.32147 30.128C4.4822 31.0924 5.31658 31.7992 6.29425 31.7992H31.7057C32.6834 31.7992 33.5178 31.0924 33.6785 30.128Z"
          stroke="currentColor"
          strokeWidth="2"
        />
        <path d="M8.19873 10.1992L12.6987 1.19922" stroke="currentColor" strokeWidth="2" />
        <path d="M29.8013 10.1992L25.3013 1.19922" stroke="currentColor" strokeWidth="2" />
      </svg>
    </>
  );
};

export default EmptyCartIcon;
