import create from 'zustand';

interface AppState {
  cartOpen: boolean;
  toggleCart: (value: boolean) => void;
}

export const useCartStore = create<AppState>((set) => ({
  cartOpen: false,
  toggleCart: (value: boolean) => {
    set(() => ({
      cartOpen: value,
    }));
  },
}));
