import React from 'react';
import { useFormik } from 'formik';

import { customerAddressSchema } from 'lib/sanford-api/validation-schemas';
import { OutlineButton } from 'components/Button';
import Input from 'components/form/TextInput';
import Select from 'components/form/Select';
// import Select from 'components/form/Select';
import CheckBox from 'components/form/Checkbox';
import { SFAddUpdateCustomerAddressRequest } from 'lib/sanford-api';
import InfoIcon from 'components/icons/InfoIcon';

type CustomerAddressFormFields = SFAddUpdateCustomerAddressRequest;

const defaultInitialValues: CustomerAddressFormFields = {
  firstName: '',
  lastName: '',
  company: '',
  phone: '',
  address1: '',
  address2: '',
  city: '',
  countryCode: 'NZ',
  stateOrProvince: '',
  postalCode: '',
};

export const newZealandStates = [
  {
    value: 'AUK',
    label: 'Auckland',
  },
  {
    value: 'BOP',
    label: 'Bay of Plenty',
  },
  {
    value: 'CAN',
    label: 'Canterbury',
  },
  {
    value: 'CIT',
    label: 'Chatham Islands Territory',
  },
  {
    value: 'GIS',
    label: 'Gisborne',
  },
  {
    value: 'WGN',
    label: 'Greater Wellington',
  },
  {
    value: 'HKB',
    label: "Hawke's Bay",
  },
  {
    value: 'MWT',
    label: 'Manawatū-Whanganui',
  },
  {
    value: 'MBH',
    label: 'Marlborough',
  },
  {
    value: 'NSN',
    label: 'Nelson',
  },
  {
    value: 'NTL',
    label: 'Northland',
  },
  {
    value: 'OTA',
    label: 'Otago',
  },
  {
    value: 'STL',
    label: 'Southland',
  },
  {
    value: 'TKI',
    label: 'Taranaki',
  },
  {
    value: 'TAS',
    label: 'Tasman',
  },
  {
    value: 'WKO',
    label: 'Waikato',
  },
  {
    value: 'WTC',
    label: 'West Coast',
  },
];

interface CustomerAddressesFormProps {
  initialValues?: CustomerAddressFormFields;
  onSubmit: (values: CustomerAddressFormFields) => void;
}

const CustomerAddressesForm: React.FC<CustomerAddressesFormProps> = (props) => {
  const addressForm = useFormik<CustomerAddressFormFields>({
    initialValues: props.initialValues || defaultInitialValues,
    validationSchema: customerAddressSchema,
    onSubmit: async (values) => {
      props.onSubmit(values);
    },
  });

  return (
    <div className="rounded bg-sf-gray-100 p-6">
      <form onSubmit={addressForm.handleSubmit} className="space-y-6">
        <div>
          <h4 className="mb-4 font-medium uppercase">Recipient</h4>
          <div className="space-y-2">
            <div className="gap-2 sm:flex">
              <div className="flex-auto">
                <Input
                  value={addressForm.values.firstName}
                  onChange={addressForm.handleChange}
                  onBlur={addressForm.handleBlur}
                  id="firstName"
                  label="First Name"
                  autoComplete="First Name"
                  type="text"
                  error={addressForm.touched.firstName && addressForm.errors.firstName}
                />
              </div>
              <div className="flex-auto">
                <Input
                  value={addressForm.values.lastName}
                  onChange={addressForm.handleChange}
                  onBlur={addressForm.handleBlur}
                  id="lastName"
                  label="Last Name"
                  autoComplete="Last Name"
                  type="text"
                  error={addressForm.touched.lastName && addressForm.errors.lastName}
                />
              </div>
            </div>
            <Input
              value={addressForm.values.company}
              onChange={addressForm.handleChange}
              onBlur={addressForm.handleBlur}
              id="company"
              label="Company Name"
              autoComplete="Company name"
              type="text"
              error={addressForm.touched.company && addressForm.errors.company}
            />
            <Input
              value={addressForm.values.phone}
              onChange={addressForm.handleChange}
              onBlur={addressForm.handleBlur}
              id="phone"
              label="Phone number"
              autoComplete="Phone number"
              type="text"
              error={addressForm.touched.phone && addressForm.errors.phone}
            />
          </div>
        </div>

        <div>
          <h4 className="mb-4 font-medium uppercase">Address</h4>
          <div className="space-y-2">
            <Input
              value={addressForm.values.address1}
              onChange={addressForm.handleChange}
              onBlur={addressForm.handleBlur}
              id="address1"
              label="Address"
              autoComplete="Address"
              type="text"
              error={addressForm.touched.address1 && addressForm.errors.address1}
            />
            <Input
              value={addressForm.values.address2}
              onChange={addressForm.handleChange}
              onBlur={addressForm.handleBlur}
              id="address2"
              label="Suburb"
              autoComplete="Suburb"
              type="text"
              error={addressForm.touched.address2 && addressForm.errors.address2}
            />
            <Input
              value={addressForm.values.city}
              onChange={addressForm.handleChange}
              onBlur={addressForm.handleBlur}
              id="city"
              label="City"
              autoComplete="City"
              type="text"
              error={addressForm.touched.city && addressForm.errors.city}
            />

            <div>
              <Input
                value={'New Zealand'}
                id="countryCode"
                label="Country"
                autoComplete="Country"
                disabled
                readOnly
                type="text"
                onBlur={() => {}}
              />
              <div className="mt-1 flex items-center pt-0.5 text-xs">
                <InfoIcon className="h-[13.3px]" />
                Only New Zealand addresses are accepted
              </div>
            </div>

            {/* <Select
              value={addressForm.values.countryCode}
              onBlur={addressForm.handleBlur}
              onChange={addressForm.handleChange}
              id={'countryCode'}
              label={'country'}
              error={addressForm.touched.countryCode && addressForm.errors.countryCode}
            >
              <option className="font-sans" value={'NZ'} selected={true}>
                New Zealand
              </option>
            </Select> */}
            <div className="gap-2 sm:flex">
              <div className="flex-1">
                <Select
                  value={addressForm.values.stateOrProvince}
                  onChange={addressForm.handleChange}
                  onBlur={addressForm.handleBlur}
                  id="stateOrProvince"
                  label="Region"
                  error={addressForm.touched.stateOrProvince && addressForm.errors.stateOrProvince}
                  // autoComplete="Sate/province"
                >
                  <>
                    <option></option>
                    {newZealandStates.map((state) => (
                      <option key={state.value} value={state.value}>
                        {state.label}
                      </option>
                    ))}
                  </>
                </Select>
              </div>
              <div className="flex-1">
                <Input
                  value={addressForm.values.postalCode}
                  onChange={addressForm.handleChange}
                  onBlur={addressForm.handleBlur}
                  id="postalCode"
                  label="Postcode"
                  // autoComplete="Postcode"
                  type="text"
                  error={addressForm.touched.postalCode && addressForm.errors.postalCode}
                />
              </div>
            </div>
          </div>
        </div>
        <CheckBox
          onBlur={() => {}}
          text="My shipping and billing address are the same."
          onChange={(value) => {}}
          id="offersAccepted"
          name="offersAccepted"
          checked={false}
        ></CheckBox>
        <OutlineButton fullWidth={true}>Save Address</OutlineButton>
        <button className="w-full text-center text-sm font-medium underline">Cancel</button>
      </form>
    </div>
  );
};

export default CustomerAddressesForm;
