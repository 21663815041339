import React, { useState } from 'react';
import CloseIcon from '../icons/CloseIcon';
import { BannersData } from 'lib/bigcommerce/types';

const Banner: React.FC<BannersData[0]> = (props) => {
  const { name, content, date_from: dateFrom, date_to: dateTo, visible } = props;
  const [dismissed, setDismissed] = useState(false);
  const banner = {
    name,
    content,
    show: !!parseInt(visible),
    from: parseInt(dateFrom) > 0 ? new Date(parseInt(dateFrom) * 1000) : null,
    to: parseInt(dateTo) > 0 ? new Date(parseInt(dateTo) * 1000) : null,
  };

  const shouldShowBanner = () => {
    if (!!!banner.from && !!!banner.to) return true;
    const today = new Date();
    return today > banner.from && today < banner.to;
  };

  return (
    <>
      {banner.show && shouldShowBanner() && !dismissed && (
        <div className="flex w-full items-center justify-center bg-sf-blue-500 px-4 py-3 md:px-8 lg:px-24 xl:px-40 2xl:px-72">
          <div className={'flex w-full max-w-7xl flex-row items-center justify-between pb-1 pt-2'}>
            <div className="flex w-full items-center justify-center pr-4">
              <div
                className={
                  'prose prose-sm prose-sf-blue relative text-center text-white sm:prose-base prose-a:text-white'
                }
                dangerouslySetInnerHTML={{ __html: content }}
              />
            </div>
            <div
              className="flex w-12 cursor-pointer items-center justify-center"
              onClick={() => setDismissed(true)}
            >
              <CloseIcon />
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default Banner;
