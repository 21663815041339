import React, { useEffect, useRef } from 'react';
import { NavigationItemData } from 'lib/contentful/types';
import gsap from 'gsap';

interface NavigationTopMenuSecondaryItemProps {
  href: string;
  index: number;
  item?: NavigationItemData;
  children?: React.ReactNode;
}

const NavigationTopMenuSecondaryItem: React.FC<NavigationTopMenuSecondaryItemProps> = (props) => {
  const { index } = props;
  const containerRef = useRef(null);
  const backgroundRef = useRef(null);

  useEffect(() => {
    const container = containerRef.current;
    const background = backgroundRef.current;

    const onMouseEnter = () => {
      gsap.to(background, { opacity: 1, duration: 0.6, ease: 'power3.out' });
    };

    const onMouseLeave = () => {
      gsap.to(background, { opacity: 0, duration: 0.6, ease: 'power3.out' });
    };

    container.addEventListener('mouseenter', onMouseEnter);
    container.addEventListener('mouseleave', onMouseLeave);

    gsap.fromTo(
      container,
      { x: -10 },
      { opacity: 1, x: 0, duration: 0.6, ease: 'power3.out', delay: 0.3 + index / 50 }
    );

    return () => {
      container.removeEventListener('mouseenter', onMouseEnter);
      container.removeEventListener('mouseleave', onMouseLeave);
    };
  }, [index]);

  return (
    <>
      <li ref={containerRef} className="relative cursor-pointer opacity-0 hover:text-sf-blue-500">
        <div ref={backgroundRef} className="absolute inset-0 opacity-0">
          <div className="absolute inset-0 overflow-hidden rounded-lg bg-sf-blue-500 opacity-5"></div>
        </div>
        <div className="relative px-6">{props.children}</div>
      </li>
    </>
  );
};

export default NavigationTopMenuSecondaryItem;
