export const MESSAGES = {
  FORM_SUBMISSION_ERROR: `Sorry, something went wrong. Please try again or contact us`,
};

export const PRODUCT_FALLBACK_IMAGE = '/assets/images/products/product-fallback-image.png';

export const ACCOUNT_NAVIGATION = [
  [
    {
      label: 'Log in',
      url: '/login',
      type: 'logout',
    },
    {
      label: 'Sign up',
      url: '/signup',
      type: 'logout',
    },
    {
      label: 'My account',
      url: '/my-account/details',
      type: 'logged',
    },
  ],
];

export const PATHS = {
  RECIPES: `/fish-and-seafood-recipes`,
};
