import { useState } from 'react';
import Input from 'components/form/TextInput';
import { useFormik } from 'formik';
import sanfordApi, { SFSubscribeRequest } from 'lib/sanford-api';
import { subscribeSchema } from 'lib/sanford-api/validation-schemas';
import React from 'react';
import { useMutation } from 'react-query';
import { FillButton } from 'components/Button';
import PhoneStrokeIcon from 'components/icons/PhoneStrokeIcon';
import classnames from 'utils/classnames';

const SubscribeForm: React.FC = (props) => {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isMutationSuccess, setIsMutationSuccess] = useState(false);
  const [isMutationError, setIsMutationError] = useState(false);

  const subscribeMutation = useMutation((newTodo) => sanfordApi.subscribe(newTodo), {
    onMutate: async (data: SFSubscribeRequest) => {
      setIsLoading(true);
      setIsSubmitted(true);
    },

    onError: (err, variables, context) => {
      setIsMutationError(true);
      setIsLoading(false);
    },

    onSuccess: (data) => {
      setIsMutationSuccess(true);
      setIsLoading(false);
      // console.log('onSuccess', 'data.data.success', data.data.success);
    },
    onSettled: () => {},
    mutationKey: 'subscribe',
  });

  const subscribeForm = useFormik<SFSubscribeRequest>({
    initialValues: {
      email: '',
      firstName: '',
    },
    validationSchema: subscribeSchema,
    onSubmit: async (values) => {
      subscribeMutation.mutate(values);
    },
  });

  return (
    <div className="md:max-w-panel m-auto w-full print:hidden">
      <div
        className={classnames(
          'flex items-center justify-center px-4 py-24 text-sf-blue-700 sm:mx-auto sm:w-full sm:px-12 sm:py-28'
        )}
      >
        <form
          onSubmit={subscribeForm.handleSubmit}
          className={classnames(
            'flex h-full min-h-[434px] w-full flex-col items-center justify-center rounded-3xl bg-sf-blue-500/[.06] p-6 font-normal sm:min-h-[192px] sm:justify-between sm:px-16 sm:py-8 xl:flex-row xl:justify-center',
            {
              'xl:flex-row':
                (isMutationSuccess && isSubmitted) ||
                (isMutationSuccess && !isSubmitted) ||
                (!isMutationSuccess && !isSubmitted),
              'md:flex-row': isSubmitted && !isMutationSuccess,
            }
          )}
        >
          {!isMutationSuccess ? (
            <div className="flex flex-col items-center justify-center sm:flex-row xl:basis-4/12">
              <div className="h-32 sm:h-44">
                <PhoneStrokeIcon className="h-32 sm:h-44" />
              </div>
              <div className="text-center text-lg uppercase leading-7 text-sf-blue-500 sm:text-left sm:text-xl 2xl:text-[28px]">
                Sign-up for product news, recipes and special offers.
              </div>
            </div>
          ) : null}

          {!isSubmitted ? (
            <>
              <div className="flex w-full flex-col items-center justify-center sm:ml-6 sm:flex-row sm:items-start lg:w-10/12 xl:ml-12 xl:basis-6/12">
                <div className="mt-4 w-full sm:m-0 sm:basis-1/3">
                  <Input
                    value={subscribeForm.values.firstName}
                    onChange={subscribeForm.handleChange}
                    onBlur={subscribeForm.handleBlur}
                    id="firstName"
                    label="First name"
                    autoComplete="First Name"
                    type="text"
                    error={subscribeForm.touched.firstName && subscribeForm.errors.firstName}
                    variant="flushed"
                  />
                </div>
                <div className="mt-4 w-full sm:m-0 sm:basis-2/3 sm:pl-4">
                  <Input
                    value={subscribeForm.values.email}
                    onChange={subscribeForm.handleChange}
                    onBlur={subscribeForm.handleBlur}
                    id="email"
                    label="Email address"
                    autoComplete="email"
                    type="email"
                    error={subscribeForm.touched.email && subscribeForm.errors.email}
                    variant="flushed"
                  />
                </div>
              </div>
              <div className="flex w-full items-center justify-center pt-6 xl:basis-2/12">
                <FillButton
                  fullWidth={true}
                  type="submit"
                  disabled={subscribeMutation.isLoading || !subscribeForm.isValid}
                  className="w-full sm:w-40"
                  color="light-blue"
                >
                  Sign-up
                </FillButton>
              </div>
            </>
          ) : null}

          {isLoading && (
            <>
              <div className="ml-4 mt-4 basis-8/12 text-center text-base text-sf-blue-500 sm:text-lg"></div>
            </>
          )}

          {isSubmitted && isMutationError ? (
            <div className="ml-4 mt-4 basis-8/12 text-center text-base text-sf-blue-500 sm:text-lg">
              Sorry, please try again later.
            </div>
          ) : null}

          {isSubmitted && isMutationSuccess ? (
            <div className="flex flex-col items-center justify-center pt-4 text-sf-blue-500 sm:flex-row">
              <div className="h-32 sm:h-44">
                <PhoneStrokeIcon className="h-32 sm:h-44" />
              </div>
              <div className="max-w-[402px] flex-col text-center sm:flex-row sm:text-left">
                <div className="text-lg uppercase sm:text-[28px]">Thanks for signing up.</div>
                <div className="pt-4 text-base sm:text-lg">
                  We’ll keep you posted with product news, recipes and special offers.
                </div>
              </div>
            </div>
          ) : null}
        </form>
      </div>
    </div>
  );
};

export default SubscribeForm;
