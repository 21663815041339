import create from 'zustand';

interface AppState {
  sideNav: boolean;
  toggleSideNav: (value: boolean) => void;
}

export const useNavStore = create<AppState>((set) => ({
  sideNav: false,
  toggleSideNav: (value: boolean) => {
    set(() => ({
      sideNav: value,
    }));
  },
}));
