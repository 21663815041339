import React, { ChangeEvent } from 'react';
import classnames from '../../utils/classnames';
import classames from '../../utils/classnames';
import InfoIcon from 'components/icons/InfoIcon';

interface Props {
  id: string;
  label: string;
  value: string;
  onBlur: (input: ChangeEvent<HTMLInputElement>) => any;
  onChange?: (input: ChangeEvent<HTMLInputElement>) => any;
  type: string;
  error?: string;
  autoComplete?: string;
  className?: string;
  required?: boolean;
  variant?: 'standard' | 'flushed';
  readOnly?: boolean;
  disabled?: boolean;
}

const rootClasses = {
  root: 'w-full text-gray-900 peer px-4 pt-8 pb-2 placeholder-transparent text-lg h-16',
};

const variantClasses = {
  standard:
    'rounded bg-gray-100 border border-sf-blue-700 border-border-base focus:shadow focus:bg-light focus:border-accent border border-opacity-20 focus:border-opacity-30 focus:outline-none',
  flushed:
    'bg-transparent border-b-[1px] border-sf-blue-500 text-sf-blue-400 focus:border-b-2 active:border-b-2 border-opacity-60',
};

const errorClasses = {
  standard: 'border-solid border-rose-600 border-opacity-95',
  flushed: 'border-solid border-sf-blue-700 border-b-2 border-opacity-95',
};

const Input: React.FC<Props> = (props) => {
  const {
    id,
    type,
    error,
    label,
    onBlur,
    onChange,
    value,
    autoComplete,
    required,
    className,
    variant = 'standard',
    readOnly,
    disabled,
  } = props;

  return (
    <>
      <div className="relative">
        <input
          id={id}
          name={id}
          readOnly={readOnly}
          disabled={disabled}
          onChange={onChange}
          type={type}
          value={value}
          onBlur={onBlur}
          autoComplete={autoComplete}
          required={required}
          placeholder={label}
          className={classames(
            className,
            rootClasses.root,
            { hidden: type === 'hidden' },
            {
              [variantClasses.standard]: variant === 'standard' && type !== 'hidden',
              [errorClasses.standard]: !!error && variant === 'standard' && type !== 'hidden',
              'cursor-not-allowed text-opacity-50': readOnly || disabled,
            },
            {
              [variantClasses.flushed]: variant === 'flushed' && type !== 'hidden',
              [errorClasses.flushed]: !!error && variant === 'flushed' && type !== 'hidden',
            }
          )}
        />
        {label && (
          <label
            htmlFor={id}
            className={classnames({
              'absolute left-4 top-2 text-sm text-sf-blue-700 transition-all peer-placeholder-shown:top-6 peer-placeholder-shown:text-base peer-placeholder-shown:text-opacity-60 peer-focus:top-2 peer-focus:text-sm peer-focus:text-opacity-60':
                variant === 'standard',
              'absolute left-4 top-2 text-sm text-sf-blue-400 transition-all peer-placeholder-shown:top-6 peer-placeholder-shown:text-base peer-focus:top-2 peer-focus:text-sm':
                variant === 'flushed',
              'pointer-events-none text-opacity-50': disabled || readOnly,
            })}
            suppressHydrationWarning
          >
            {label}
          </label>
        )}

        {error && (
          <>
            <div
              className={classnames('mt-1 flex items-center text-left text-xs', {
                'text-rose-600': variant === 'standard',
                'text-sf-blue-700': variant === 'flushed',
              })}
            >
              <InfoIcon className="h-[13.3px]" />
              <div>{error}</div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default Input;
