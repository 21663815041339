interface ProfileIconProps {
  className?: string;
}

const ProfileIcon: React.FC<ProfileIconProps> = (props) => {
  return (
    <>
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={props.className}
      >
        <path
          d="M10.0684 21.7364C11.8387 20.6028 13.8969 20.0002 15.9991 20C18.1013 19.9998 20.1596 20.602 21.9302 21.7353"
          stroke="currentColor"
          strokeWidth="2"
        />
        <rect x="4" y="4" width="24" height="24" rx="12" stroke="currentColor" strokeWidth="2" />
        <rect x="13" y="10" width="6" height="6" rx="3" stroke="currentColor" strokeWidth="2" />
      </svg>
    </>
  );
};

export default ProfileIcon;
