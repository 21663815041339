import { ProductData } from 'lib/bigcommerce/types';
import { SFCart, SFCartItem } from 'lib/sanford-api';

declare const window: Window & {
  dataLayer: Record<string, unknown>[];
  gtag: (event: string, action: string, data: Record<string, unknown>) => void;
};

type EcommerceObject = {
  event:
    | 'view_item_list'
    | 'select_item'
    | 'view_item'
    | 'add_to_cart'
    | 'remove_from_cart'
    | 'view_cart';
  ecommerce: Record<string, unknown>;
};

const replaceSpaces = (field: string, replacement = '_') => {
  return field.replaceAll(' ', replacement);
};

/**
 * TODO::Review and remove this comments
 * - I've found the way using dataLayer.push() in this URL: https://developers.google.com/analytics/devguides/collection/ua/gtm/enhanced-ecommerce#product-clicks
 * - I've found the way using gtag() in this URL: https://developers.google.com/analytics/devguides/migration/ecommerce/gtagjs-compatibility
 * I still can't tell which way is meant to be using, but gtag() is showing on the google analytics dashboard.
 */
const tracketEcomEvent = (item: EcommerceObject) => {
  if (window !== undefined && window.dataLayer && window.gtag) {
    const { dataLayer, gtag } = window;
    gtag('event', item.event, item.ecommerce);
    // dataLayer.push({ ecommerce: null }); //always clear the ecommerce object. This trick is from the official google docs.
    // dataLayer.push(item);
    console.log(item);
  }
};

const viewList = (
  list_id: string,
  list_name: string,
  items: { item_id: string; item_name: string }[]
) => {
  tracketEcomEvent({
    event: 'view_item_list',
    ecommerce: {
      item_list_id: replaceSpaces(list_id),
      item_list_name: list_name,
      items,
    },
  });
};

const selectItemFromList = (
  items: { item_id: string; item_name: string; item_category: string }[]
) => {
  tracketEcomEvent({
    event: 'select_item',
    ecommerce: {
      items,
    },
  });
};

const viewItemDetails = (item: ProductData) => {
  tracketEcomEvent({
    event: 'view_item',
    ecommerce: {
      currency: 'NZD',
      value: item.price,
      items: [
        {
          item_id: item.sku,
          item_name: item.name,
        },
      ],
    },
  });
};

const addItemToCart = (item: SFCartItem, quantity: number) => {
  tracketEcomEvent({
    event: 'add_to_cart',
    ecommerce: {
      currency: 'NZD',
      value: quantity * item.price,
      items: [
        {
          item_id: item.sku,
          item_category: item.purchaseType.label,
          discount: item.discount,
          item_name: item.name,
          price: item.price,
          quantity,
        },
      ],
    },
  });
};

const removeItemFromCart = (item: SFCartItem, quantity: number) => {
  tracketEcomEvent({
    event: 'remove_from_cart',
    ecommerce: {
      currency: 'NZD',
      value: quantity * item.price,
      items: [
        {
          item_id: item.sku,
          item_name: item.name,
          item_category: item.purchaseType.label,
          discount: item.discount,
          price: item.price,
          quantity,
        },
      ],
    },
  });
};

const viewCart = (cart: SFCart) => {
  tracketEcomEvent({
    event: 'view_cart',
    ecommerce: {
      currency: 'NZD',
      value: cart.price,
      items: cart.lineItems.map((line) => ({
        item_id: line.sku,
        item_name: line.name,
        // item_category: "", //we don't have a category.
        discount: line.discount,
        price: line.price,
        quantity: line.quantity,
        purchase_mode: line.purchaseType.label,
      })),
    },
  });
};

const track = {
  viewList,
  selectItemFromList,
  viewItemDetails,
  addItemToCart,
  removeItemFromCart,
  viewCart,
};

export default track;
