import HorizontalLine from 'components/ui/HorizontalLine';
import InfoIcon from 'components/icons/InfoIcon';
import { PurchaseType } from 'lib/bigcommerce/product-options';
import { SFCart } from 'lib/sanford-api';
import React from 'react';
import InfoNote from '../ui/InfoNote';
import CartPurchaseTypeList from './CartPurchaseTypeList';
import CartSubscriptionList from './CartSubscriptionList';

interface CartPurchaseProps {
  purchaseTypeId: SFCart['purchaseTypeId'];
}

const CartPurchaseType: React.FC<CartPurchaseProps> = (props) => {
  const { purchaseTypeId } = props;

  const subscriptions: any[] = [
    {
      label: 'Wednesday Weekly',
      id: 'wednesday-weekly-1234567890',
    },
  ];
  const currentSubscription = subscriptions.length ? subscriptions[0].id : '';

  return (
    <>
      <div className="relative w-full">
        {/* {purchaseTypeId === PurchaseType.Subscription.id && (
          <div className="flex flex-col gap-y-6">
            <div className="flex flex-col gap-y-6">
              <div>
                <InfoNote>
                  <div className="relative flex w-full items-center justify-center gap-x-3">
                    <div>
                      <InfoIcon />
                    </div>
                    <div className="w-full">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Adipiscing tincidunt
                      nisl, fringilla vitae aliquet pellentesque purus ultricies ut.
                    </div>
                  </div>
                </InfoNote>
              </div>
              <HorizontalLine />
            </div>
            <div>
              <p className="text-lg font-medium">Choose a subscription option </p>
            </div>
            <CartSubscriptionList
              subscriptions={subscriptions}
              currentSubscription={currentSubscription}
            /> 
            <HorizontalLine />
          </div>
        )}*/}
        {purchaseTypeId === null && (
          <>
            <div className="relative">
              <div className="absolute inset-0 border-2 border-sf-blue-700 opacity-5"></div>
              <div className="relative px-6 py-6">
                <div className="flex flex-col gap-y-6">
                  <div className="flex flex-col gap-y-6">
                    <div>
                      <InfoNote>
                        <div className="relative flex w-full items-center justify-center gap-x-3">
                          <div>
                            <InfoIcon />
                          </div>
                          <div className="w-full">
                            Mixed order You can only place an order for subscription products or
                            one-time products at a time. Please choose an option below and your cart
                            will be updated.
                          </div>
                        </div>
                      </InfoNote>
                    </div>
                    <HorizontalLine />
                  </div>
                  <div>
                    <CartPurchaseTypeList purchaseTypeId={purchaseTypeId} />
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default CartPurchaseType;
