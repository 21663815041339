interface FilterOffIconProps {
  className?: string;
}

const FilterOffIcon: React.FC<FilterOffIconProps> = (props) => {
  return (
    <>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={props.className}
      >
        <rect x="1" y="1" width="18" height="18" rx="9" stroke="currentColor" strokeWidth="2" />
      </svg>
    </>
  );
};

export default FilterOffIcon;
