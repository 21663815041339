import React from 'react';

const InfoNote: React.FC<{ children?: React.ReactNode }> = (props) => {
  return (
    <>
      <div className="relative w-full text-xs">
        <div className="absolute inset-0 rounded-md bg-sf-blue-500 opacity-5"></div>
        <div className="relative w-full px-3 py-4">{props.children}</div>
      </div>
    </>
  );
};

export default InfoNote;
