interface CartIconProps {
  className?: string;
}

const CartIcon: React.FC<CartIconProps> = (props) => {
  return (
    <>
      <svg
        width="48"
        height="48"
        viewBox="0 0 48 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={props.className}
      >
        <path
          d="M24 48C10.7452 48 -4.69686e-07 37.2548 -1.04907e-06 24C-1.62846e-06 10.7452 10.7452 3.34501e-06 24 2.76562e-06C37.2548 2.18624e-06 48 10.7452 48 24C48 37.2548 37.2548 48 24 48Z"
          fill="currentColor"
        />
        <path
          d="M32.2785 30.3288L33.6119 22.3288C33.815 21.1097 32.875 20 31.6391 20H16.3609C15.125 20 14.185 21.1097 14.3881 22.3288L15.7215 30.3288C15.8822 31.2932 16.7166 32 17.6943 32H30.3057C31.2834 32 32.1178 31.2932 32.2785 30.3288Z"
          stroke="white"
          strokeWidth="2"
        />
        <path d="M18 20L20.5 15" stroke="white" strokeWidth="2" />
        <path d="M30 20L27.5 15" stroke="white" strokeWidth="2" />
      </svg>
    </>
  );
};

export default CartIcon;
