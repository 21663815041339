interface SearchIconProps {
  className?: string;
}

const SearchIcon: React.FC<SearchIconProps> = (props) => {
  return (
    <>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={props.className}
      >
        <path
          d="M13.9497 4.05025C16.6834 6.78392 16.6834 11.2161 13.9497 13.9497L15.364 15.364C18.8787 11.8492 18.8787 6.15076 15.364 2.63604L13.9497 4.05025ZM13.9497 13.9497C11.2161 16.6834 6.78392 16.6834 4.05025 13.9497L2.63604 15.364C6.15076 18.8787 11.8492 18.8787 15.364 15.364L13.9497 13.9497ZM4.05025 13.9497C1.31658 11.2161 1.31658 6.78392 4.05025 4.05025L2.63604 2.63604C-0.87868 6.15076 -0.87868 11.8492 2.63604 15.364L4.05025 13.9497ZM4.05025 4.05025C6.78392 1.31658 11.2161 1.31658 13.9497 4.05025L15.364 2.63604C11.8492 -0.87868 6.15076 -0.87868 2.63604 2.63604L4.05025 4.05025ZM13.2426 14.6569L18.1924 19.6066L19.6066 18.1924L14.6569 13.2426L13.2426 14.6569Z"
          fill="currentColor"
        />
      </svg>
    </>
  );
};

export default SearchIcon;
