import React from 'react';
import FacebookIcon from 'components/icons/FacebookIcon';
import InstagramIcon from 'components/icons/InstagramIcon';
import Link from 'components/Link';
import { SiteSettingsData } from 'lib/contentful/types';
import Container from './Container';
import SanfordAndSonsFishmongersWhiteIcon from '../icons/SanfordAndSonsFishmongersWhiteIcon';

interface FooterAddressProps {
  instagramLink?: string;
  facebookLink?: string;
  address: string;
}

interface FooterEmailProps {
  email: string;
}

const FooterAddress: React.FC<FooterAddressProps> = (props) => {
  const { instagramLink, facebookLink, address } = props;
  return (
    <>
      <div className="lg:flex lg:flex-col-reverse">
        <div className="">
          <div className="flex items-start gap-x-3 lg:gap-x-5">
            {instagramLink && (
              <>
                <a href={instagramLink} target={'_blank'} className={''} rel="noreferrer">
                  <InstagramIcon className="w-6 lg:w-7" />
                </a>
              </>
            )}
            {facebookLink && (
              <>
                <a href={facebookLink} target={'_blank'} className={''} rel="noreferrer">
                  <FacebookIcon className="w-6 lg:w-7" />
                </a>
              </>
            )}
          </div>
        </div>
        <div>
          {address && (
            <>
              <div>
                <div className="pt-6 text-lg font-medium lg:pb-1 lg:pt-0">{`Retail Store`}</div>
                <div className="pt-4 text-base font-normal lg:pb-4 lg:pt-2">
                  <div className="lg:max-w-[268px]">
                    <span>{address}</span>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

const FooterEmail: React.FC<FooterEmailProps> = (props) => {
  const { email } = props;
  return (
    <div className="flex flex-col">
      {email && (
        <div className="pt-4 text-base font-normal lg:max-w-[268px] lg:pb-4">
          <a href={`mailto:${email}`}>{email}</a>
        </div>
      )}
    </div>
  );
};

interface FooterProps {
  address: string;
  facebookLink: string;
  instagramLink: string;
  copyright: string;
  email: string;
  navigationGroup: SiteSettingsData['footerNavigationGroup'];
  logo?: SiteSettingsData['footerLogo'];
}

const Footer: React.FC<FooterProps> = (props) => {
  const { navigationGroup, instagramLink, facebookLink, address, email, copyright, logo } = props;

  const footerAdress: FooterAddressProps = {
    instagramLink,
    facebookLink,
    address,
  };

  const footerEmail: FooterEmailProps = {
    email,
  };
  return (
    <>
      <div className="relative w-full bg-sf-blue-700 pb-32 pt-10 text-white lg:pb-28 lg:pt-20 print:hidden">
        <Container>
          <div className="relative mx-auto lg:w-10/12 ">
            <div className="relative flex w-full flex-row items-center justify-center pb-10 lg:justify-start">
              <div className="w-full max-w-[336px]">
                <SanfordAndSonsFishmongersWhiteIcon className="w-full"></SanfordAndSonsFishmongersWhiteIcon>
              </div>
            </div>
            <div className="lg:pb-32 lg:pt-20">
              <div className="relative w-full lg:flex lg:flex-nowrap lg:justify-between lg:gap-x-10">
                <div className="relative hidden lg:block">
                  <FooterAddress {...footerAdress}></FooterAddress>
                  <div className={'hidden sm:block'}>
                    <FooterEmail {...footerEmail}></FooterEmail>
                  </div>
                </div>

                {navigationGroup.map((item) => {
                  return (
                    <div key={item.id} className="">
                      <div className="text-lg font-medium lg:pb-1">{item.title}</div>
                      <ul className="text-base font-normal">
                        {item.navItems.map((navItem) => (
                          <li key={navItem.sys.id} className="pt-4 lg:pt-2">
                            <Link {...navItem} />
                          </li>
                        ))}
                      </ul>
                      <div className="relative my-6 w-full lg:hidden">
                        <div className="relative h-[2px] w-full bg-white opacity-10"></div>
                      </div>
                    </div>
                  );
                })}

                <div className="relative lg:hidden">
                  <FooterAddress {...footerAdress}></FooterAddress>
                </div>
              </div>
            </div>
            <div className="relative w-full pt-10">
              <div className={'-mt-4 block pb-6 sm:hidden'}>
                <FooterEmail {...footerEmail}></FooterEmail>
              </div>
              <div className="text-xs font-normal">{copyright && <>&copy; {copyright}</>}</div>
            </div>
          </div>
        </Container>
      </div>
    </>
  );
};

export default Footer;
