import SpinnerIcon from 'components/icons/SpinnerIcon';
import gsap from 'gsap';
import { useEffect, useRef } from 'react';

interface CartPreloaderProps {
  isLoading: boolean;
}

const CartPreloader: React.FC<CartPreloaderProps> = (props) => {
  const { isLoading } = props;

  const containerRef = useRef(null);
  const spinnerRef = useRef(null);

  useEffect(() => {
    const spinner = spinnerRef.current;

    gsap.fromTo(spinner, { rotation: 0 }, { rotation: 360, duration: 1, ease: 'none', repeat: -1 });

    return () => {
      gsap.killTweensOf(spinner);
    };
  }, []);

  useEffect(() => {
    const container = containerRef.current;

    if (isLoading) {
      gsap.set(container, { opacity: 1, pointerEvents: 'none' });
    } else {
      gsap.to(container, {
        opacity: 0,
        duration: 0.3,
        ease: 'power3.out',
        onComplete: () => {
          gsap.set(container, { pointerEvents: 'none' });
        },
      });
    }

    return () => {
      gsap.killTweensOf(container);
    };
  }, [isLoading]);

  return (
    <>
      <div
        ref={containerRef}
        className="absolute inset-0 flex items-center justify-center bg-white"
      >
        <div className="relative">
          <div ref={spinnerRef} className="relative">
            <SpinnerIcon />
          </div>
        </div>
      </div>
    </>
  );
};

export default CartPreloader;
