/**
 * @todo
 * [] we need to use next render for contentful ima to generate responsive images on the fly using contentful api.
 * [] We also need to convert images to webp for better performance
 * [] add lazy loading so the images are loaded only when available on the view port
 * [] we can add a small fade out effect once the images are loaded maybe?\\
 * [] preload the image.
 *  docs https://nextjs.org/docs/basic-features/image-optimization
 *  https://www.contentfulcommunity.com/t/automatic-image-format-selection/5157/6
 * https://twitter.com/leeerob/status/1352267458049093632
 * https://uploadcare.com/blog/next-js-image-optimization/
 *
 *
 */

import { ContenfulImageAssetData } from 'lib/contentful/types';
import NextImage from 'next/image';
import React, { useState } from 'react';

type ImageProps = ContenfulImageAssetData & {
  quality?: number;
  format?: 'jpg' | 'webp' | 'png';
  alt?: string;
  type?: 'fixed' | 'fluid';
  objectFit?: `fill` | `contain` | `cover` | `none` | `scale-down`;
  objectPosition?: string;
  layout?: 'fixed' | 'fill' | 'intrinsic' | 'responsive';
  aspectRatio?: number;
  priority?: boolean;
  className?: string;
  sizes?: string;
  focalPoint?: {
    focalPoint: {
      x: number;
      y: number;
    };
  };
  fallback?: string;
};

const Img: React.FC<ImageProps> = (props) => {
  const {
    width,
    height,
    url,
    quality,
    format,
    title,
    alt,
    priority,
    description,
    className,
    objectFit,
    sizes,
    layout,
    fallback,
  } = props;

  const imgAlt = alt || description || title || '';

  return (
    <NextImage
      width={width}
      objectFit={objectFit}
      height={height}
      priority={priority}
      sizes={sizes}
      layout={layout}
      className={className}
      alt={imgAlt}
      src={url || fallback}
      loader={() => `${url}?w=${width}&q=${quality || 75}&fit=fill&f=face&fm=${format}`}
      quality={quality}
    />
  );
};

Img.defaultProps = {
  quality: 90,
  format: 'webp',
};

export default Img;
