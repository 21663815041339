interface PhoneStrokeIconProps {
  className?: string;
}

const PhoneStrokeIcon: React.FC<PhoneStrokeIconProps> = (props) => {
  return (
    <>
      <svg
        width="160"
        height="160"
        viewBox="0 0 160 160"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={props.className}
      >
        <path
          d="M57.5 66V46C57.5 42.6863 60.1863 40 63.5 40H90.5C93.8137 40 96.5 42.6863 96.5 46V85.5M57.5 94V110C57.5 113.314 60.1863 116 63.5 116H94.5"
          stroke="#1C5E9B"
          strokeWidth="2"
        />
        <path d="M57.5 48H96.5" stroke="#1C5E9B" strokeWidth="2" />
        <path d="M57.5 108H89.5" stroke="#1C5E9B" strokeWidth="2" />
        <path d="M72.5 33.5L71 18" stroke="#1C5E9B" strokeWidth="2" strokeLinecap="round" />
        <path d="M82 33.5L83 25.5" stroke="#1C5E9B" strokeWidth="2" strokeLinecap="round" />
        <path d="M84 20.5L84.5 17" stroke="#1C5E9B" strokeWidth="2" strokeLinecap="round" />
        <path d="M91 34.5L95 25" stroke="#1C5E9B" strokeWidth="2" strokeLinecap="round" />
        <path d="M100.5 39L104 35" stroke="#1C5E9B" strokeWidth="2" strokeLinecap="round" />
        <path d="M104 48L107 46.5" stroke="#1C5E9B" strokeWidth="2" strokeLinecap="round" />
        <path d="M63.5 35L61.5 31" stroke="#1C5E9B" strokeWidth="2" strokeLinecap="round" />
        <path d="M59.5 27L58.5 25" stroke="#1C5E9B" strokeWidth="2" strokeLinecap="round" />
        <path d="M54 39L48 32.5" stroke="#1C5E9B" strokeWidth="2" strokeLinecap="round" />
        <path d="M50.5 47.5L45 45" stroke="#1C5E9B" strokeWidth="2" strokeLinecap="round" />
        <path
          d="M84.5 78.4858C84.8214 76.9983 85 75.4968 85 74C85 64.3346 82.5175 58.6907 80.7661 56.0402C80.4049 55.4937 79.6279 55.5209 79.2472 56.0539L78.5329 57.054C78.0797 57.6884 77.1071 57.5833 76.8 56.8667L76.5554 56.296C76.284 55.6627 75.4821 55.501 75.0668 56.0509C73.0725 58.6915 70.5 64.5695 70.5 74C70.5 83.7286 76.3664 93.0661 82.565 98.5535C83.2935 99.1984 82.8524 100.5 81.8795 100.5H72.9479C71.9923 100.5 71.5367 99.234 72.2315 98.5779C74.9456 96.0151 78.26 92.1354 80.8317 87.6462"
          stroke="#1C5E9B"
          strokeWidth="2"
        />
        <circle cx="76" cy="65.5" r="1.5" fill="#1C5E9B" />
        <path
          d="M114 129.5C111.5 126.833 106.2 119.5 105 111.5C103.5 101.5 103 89.5 93.5002 84C84.0002 78.5 81.0002 75 78.0002 78C75.0002 81 77.5002 84.5 79.5002 86.5C81.5002 88.5 87.5 91 88 95.5C88.5 100 87 111.5 96.5 117.5"
          stroke="#1C5E9B"
          strokeWidth="2"
        />
        <rect
          x="53.2677"
          y="70.3035"
          width="7.40712"
          height="19.29"
          rx="3.70356"
          transform="rotate(-45 53.2677 70.3035)"
          stroke="#1C5E9B"
          strokeWidth="2"
        />
        <rect
          x="50.151"
          y="77.859"
          width="7.53909"
          height="19.29"
          rx="3.76954"
          transform="rotate(-45 50.151 77.859)"
          stroke="#1C5E9B"
          strokeWidth="2"
        />
        <rect
          x="50.9142"
          y="87.7184"
          width="6.42678"
          height="16.4624"
          rx="3.21339"
          transform="rotate(-45 50.9142 87.7184)"
          stroke="#1C5E9B"
          strokeWidth="2"
        />
        <path
          d="M69.5 116C69.5 121 75 126.5 80.5 129.5C86 132.5 90.1667 139 92.5 143"
          stroke="#1C5E9B"
          strokeWidth="2"
        />
      </svg>
    </>
  );
};

export default PhoneStrokeIcon;
