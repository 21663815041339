import { FormEvent, useRef } from 'react';
import SearchIcon from 'components/icons/SearchIcon';
import { useRouter } from 'next/router';

const NavigationSideMenuSearch: React.FC = () => {
  const router = useRouter();
  const formRef = useRef(null);

  const onFormSubmit = (event: FormEvent) => {
    event.preventDefault();

    const form = formRef.current;
    const formData: FormData = new FormData(form);

    const query = [];
    formData.forEach((value, key) => {
      query.push(`${key}=${value}`);
    });

    const url = `${form.action}?${query.join('&')}`;
    router.push(url);
  };

  return (
    <>
      <form
        ref={formRef}
        className="relative w-full py-3"
        action="/search"
        method="GET"
        onSubmit={onFormSubmit}
      >
        <div className="relative w-full rounded-full border-2 border-sf-blue-500 px-6 py-3.5">
          <input
            name="q"
            type={'text'}
            placeholder={'Search'}
            className="relative bg-transparent text-sf-blue-700 placeholder-sf-blue-300"
          />
          <button className="absolute bottom-0 right-0 top-0 flex items-center justify-center pl-2 pr-6">
            <div className="relative flex items-center justify-center">
              <SearchIcon></SearchIcon>
            </div>
          </button>
        </div>
      </form>
    </>
  );
};

export default NavigationSideMenuSearch;
