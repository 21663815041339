import { NavigationItemData } from 'lib/contentful/types';
import Link, { resolveNavigationPageUrl } from 'components/Link';
import { useEffect, useRef } from 'react';
import NavigationTopMenuSecondaryItem from './NavigationTopMenuSecondaryItem';
import gsap from 'gsap';
import NavigationTopSecondaryMenuImage from './NavigationTopSecondaryMenuImage';

interface NavigationTopSecondaryMenuProps {
  currentNavigationItem: NavigationItemData;
}

const NavigationTopSecondaryMenu: React.FC<NavigationTopSecondaryMenuProps> = (props) => {
  const { currentNavigationItem } = props;
  const containerRef = useRef(null);
  const topBarRef = useRef(null);
  const backgroundRef = useRef(null);

  useEffect(() => {
    const topBar = topBarRef.current;
    const background = backgroundRef.current;

    gsap.fromTo(topBar, { x: '-100%' }, { x: '0%', duration: 1, ease: 'power3.inOut' });
    gsap.fromTo(background, { opacity: 0 }, { opacity: 1, duration: 0.3, ease: 'power3.inOut' });

    return () => {
      gsap.killTweensOf([topBar, background]);
    };
  }, []);

  return (
    <>
      <div ref={containerRef} className="relative w-full">
        <div ref={backgroundRef} className="absolute inset-0 opacity-0">
          <div className="absolute inset-0 -translate-y-full transform bg-white"></div>
          <div className="absolute inset-0 bg-white"></div>
        </div>
        <div className="relative w-full px-6">
          <div className="relative h-[2px] w-full overflow-hidden">
            <div
              ref={topBarRef}
              className="absolute inset-0 -translate-x-full transform bg-sf-blue-700 opacity-5"
            ></div>
          </div>
          <div className="relative flex w-full items-center justify-center gap-x-10 py-10">
            {currentNavigationItem && (
              <NavigationTopSecondaryMenuImage currentNavigationItem={currentNavigationItem} />
            )}
            <div className="w-full">
              <ul className="relative grid w-full grid-cols-4 gap-x-6">
                {currentNavigationItem.subItems.map((item, index) => {
                  const { href } = resolveNavigationPageUrl(item);

                  return (
                    <NavigationTopMenuSecondaryItem key={item.id} href={href} index={index}>
                      <Link {...item.link} className="inline-block py-6 text-xl font-normal" />
                    </NavigationTopMenuSecondaryItem>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NavigationTopSecondaryMenu;
