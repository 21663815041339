import { OutlineLinkButton } from 'components/Button';
import { PurchaseType } from 'lib/bigcommerce/product-options';
import sanfordApi from 'lib/sanford-api';
import { QUERIES } from 'queries';
import { useState } from 'react';
import { useQueryClient } from 'react-query';
import CartPurchaseTypeOption from './CartPurchaseTypeOption';

interface CartPurchaseTypeListProps {
  className?: string;
  purchaseTypeId?: number;
}

const CartPurchaseTypeList: React.FC<CartPurchaseTypeListProps> = (props) => {
  const { purchaseTypeId } = props;

  const [activePurchaseTypeId, setActivePurchaseTypeId] = useState(purchaseTypeId);

  const queryClient = useQueryClient();

  const handleSetPurchaseType = (purchasetypeId: number) => {
    sanfordApi
      .setCartPurchaseType({
        purchaseTypeId: purchasetypeId,
      })
      .then(() => queryClient.invalidateQueries([QUERIES.CART]))
      .catch(() => alert('Something went wrong'));
  };

  return (
    <div className="flex flex-col gap-y-6">
      <div className="relative flex w-full flex-col items-center justify-center gap-y-6 md:flex-row md:gap-x-6">
        <CartPurchaseTypeOption
          theme="light"
          purchaseTypeId={PurchaseType.Subscription.id}
          activePurchaseTypeId={activePurchaseTypeId}
          onClick={() => {
            setActivePurchaseTypeId(PurchaseType.Subscription.id);
          }}
        >
          Subscription products only
        </CartPurchaseTypeOption>
        <CartPurchaseTypeOption
          theme="dark"
          purchaseTypeId={PurchaseType.OneOff.id}
          activePurchaseTypeId={activePurchaseTypeId}
          onClick={() => {
            setActivePurchaseTypeId(PurchaseType.OneOff.id);
          }}
        >
          One-time products only
        </CartPurchaseTypeOption>
      </div>
      <div className="">
        {activePurchaseTypeId && (
          <>
            <OutlineLinkButton
              onClick={() => {
                handleSetPurchaseType(activePurchaseTypeId);
              }}
              buttonSize="small"
            >
              <span className="">Confirm change</span>
            </OutlineLinkButton>
          </>
        )}
      </div>
    </div>
  );
};

export default CartPurchaseTypeList;
