import HorizontalLine from 'components/ui/HorizontalLine';
import BinIcon from 'components/icons/BinIcon';
import Img from 'components/Img';
import Quantity from 'components/ui/Quantity';
import { PurchaseType } from 'lib/bigcommerce/product-options';
import sanfordApi, { SFCart, SFCartItem } from 'lib/sanford-api';
import { QUERIES } from 'queries';
import { useQueryClient } from 'react-query';
import { numberToCurrency } from 'utils/currency';
import SubscriptionBadge from 'components/ui/SubscriptionBadge';
import track from 'lib/tracking';

interface CartItemProps {
  cartResults: SFCart;
  product: SFCartItem;
}

const CartItem: React.FC<CartItemProps> = (props) => {
  const { cartResults, product } = props;

  const queryClient = useQueryClient();

  const handleRemoveItem = (item: SFCartItem) => {
    sanfordApi
      .removeCartItem({ itemId: item.id })
      .then(() => {
        queryClient.invalidateQueries([QUERIES.CART]);
      })
      .then(() => {
        track.removeItemFromCart(item, item.quantity);
      })
      .catch(() => alert('Error Removing Item'));
  };

  const handleQuantity = (params: {
    productId: number;
    itemId: string;
    quantity: number;
    variantId: number;
  }) => {
    sanfordApi
      .updateCartItem({ ...params })
      .then(() => queryClient.invalidateQueries([QUERIES.CART]))
      .catch(() => alert('Error Changing Quantity'));
  };

  return (
    <>
      <div className="text-sf-blue-700" key={product.id}>
        <HorizontalLine />
        <div className="relative w-full py-6">
          <div className="flex flex-col items-center justify-center gap-4 md:flex-row">
            <div className="relative w-full md:w-auto">
              <div className=" overflow-hidden rounded-md">
                <div className="relative w-full md:hidden">
                  <Img
                    width={500}
                    height={500}
                    url={product.imageUrl.src}
                    fallback={product.imageUrl.fallback}
                    layout={'responsive'}
                  />
                </div>
                <div className="relative hidden md:block md:h-[148px] md:w-[148px]">
                  {/* todo: @backend image url does not come as big commerce responsive image */}
                  <Img
                    width={500}
                    height={500}
                    url={product.imageUrl.src}
                    fallback={product.imageUrl.fallback}
                    layout={'fill'}
                    objectFit={'contain'}
                  />
                </div>
                {product.purchaseType.id === PurchaseType.Subscription.id && (
                  <>
                    <div className="pointer-events-none absolute left-2 top-2">
                      <SubscriptionBadge type="small"></SubscriptionBadge>
                    </div>
                  </>
                )}
              </div>
            </div>
            <div className="flex w-full flex-col items-center justify-center gap-y-1.5">
              <div className="relative flex w-full items-center justify-between gap-x-6">
                <div>
                  <p className="text-base font-medium">{product.name}</p>
                </div>
                <div>
                  <button onClick={() => handleRemoveItem(product)}>
                    <BinIcon />
                  </button>
                </div>
              </div>
              <div className="relative w-full">
                {product.weightSummary && (
                  <p className="text-xs">
                    {product.weightSummary} |{' '}
                    {numberToCurrency(product.price, cartResults.currency)}
                  </p>
                )}
              </div>
              <div className="relative w-full">
                <p className="text-sm font-medium">
                  {numberToCurrency(product.totalPrice, cartResults.currency)}
                </p>
              </div>
              <div className="relative w-full">
                <Quantity
                  quantity={product.quantity}
                  onChange={(newQuantity: number) => {
                    handleQuantity({
                      productId: product.productId,
                      itemId: product.id,
                      variantId: product.variantId,
                      quantity: newQuantity,
                    });
                    const difference = newQuantity - product.quantity;
                    const trackProductChange =
                      difference > 0 ? track.addItemToCart : track.removeItemFromCart;
                    trackProductChange(product, Math.abs(difference));
                  }}
                ></Quantity>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CartItem;
