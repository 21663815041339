interface SanfordAndSonsIconProps {
  className?: string;
}

const SanfordAndSonsIcon: React.FC<SanfordAndSonsIconProps> = (props) => {
  return (
    <>
      <svg
        viewBox="0 0 336 28"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={props.className}
      >
        <path
          d="M21.152 25.4638L22.2049 19.8642H22.4365C22.9465 20.6141 23.6306 21.2239 24.4278 21.6394C25.225 22.0548 26.1107 22.2631 27.0059 22.2456C29.038 22.2456 30.2593 21.2373 30.2593 19.596C30.2593 17.9548 28.1535 16.8177 26.1847 15.2408C23.8157 13.3636 21.6468 11.7546 21.6468 8.24681C21.6468 4.0847 24.9002 0.941665 29.9013 0.941665C31.5705 0.927931 33.2216 1.29443 34.7339 2.01437L33.8811 7.14189H33.6179C33.1447 6.6826 32.586 6.32451 31.9748 6.08869C31.3637 5.85287 30.7124 5.74413 30.0593 5.76886C28.4168 5.76886 26.9743 6.63772 26.9743 8.35406C26.9743 10.0704 28.8168 10.9607 31.0489 12.6449C33.6179 14.5865 35.5868 16.2921 35.5868 19.7784C35.5868 23.962 32.1965 27.0514 27.5008 27.0514C25.2812 27.1303 23.0836 26.5807 21.152 25.4638Z"
          fill="currentColor"
        />
        <path
          d="M54.2435 26.5365C54.7068 25.9358 54.7068 25.2278 54.2435 23.3827L53.7487 21.6127H45.5574L45.0309 23.5222C44.5782 25.26 44.5782 25.968 45.0309 26.5365H38.1873C38.8191 26.1718 39.0823 25.3351 39.5771 23.7904L46.6102 1.47804H52.759L59.8026 23.8225C60.287 25.3672 60.5502 26.204 61.1819 26.5365H54.2435ZM52.601 17.5579L49.674 7.09902H49.5688L46.6419 17.5579H52.601Z"
          fill="currentColor"
        />
        <path
          d="M64.7721 19.6283V8.34334C64.7721 4.95358 64.6458 2.8082 64.0456 1.47804H69.4468L79.1437 16.2171L80.1966 18.0299H80.3966V6.70213C80.3966 3.95599 80.2282 2.50784 79.6701 1.47804H86.2821C85.6609 2.8082 85.5241 4.95358 85.5241 8.34334V19.6283C85.5241 23.018 85.6609 25.1634 86.2821 26.5365H80.923L71.1525 12.4304L70.0996 10.7248H69.8996V21.3124C69.8996 24.0586 70.068 25.496 70.626 26.5365H64.0141C64.6669 25.1956 64.7721 23.018 64.7721 19.6283Z"
          fill="currentColor"
        />
        <path
          d="M107.866 6.93809C106.813 6.36956 105.434 6.19792 102.738 6.19792H97.474V12.1622H101.685C102.981 12.2351 104.279 12.1122 105.539 11.7975V16.5817C104.282 16.2504 102.981 16.1273 101.685 16.2171H97.474V21.3124C97.474 24.0586 97.6004 25.496 98.19 26.5365H91.6201C92.2202 25.1634 92.3466 23.018 92.3466 19.6283V8.34334C92.3466 4.95358 92.2202 2.8082 91.6201 1.47804H107.866V6.93809Z"
          fill="currentColor"
        />
        <path
          d="M109.982 13.9751C109.982 6.13356 115.183 0.941686 122.448 0.941686C129.713 0.941686 134.977 6.13356 134.977 13.9751C134.977 21.8166 129.786 27.0406 122.448 27.0406C115.109 27.0406 109.982 21.8487 109.982 13.9751ZM129.649 13.9751C129.649 8.94406 126.691 5.83324 122.479 5.83324C118.268 5.83324 115.352 8.94406 115.352 13.9751C115.352 19.0061 118.236 22.1491 122.479 22.1491C126.723 22.1491 129.649 19.0275 129.649 13.9751Z"
          fill="currentColor"
        />
        <path
          d="M139.188 19.6283V8.34334C139.188 4.95358 139.052 2.8082 138.462 1.47804H149.38C154.739 1.47804 158.393 4.19198 158.393 9.51262C158.458 11.0142 158.088 12.5021 157.328 13.7901C156.567 15.0781 155.451 16.1089 154.118 16.7534L156.908 23.018C157.961 25.3672 158.487 26.2361 159.182 26.5365H152.307C152.697 25.9358 152.507 25.1634 151.686 23.1897L149.38 17.8261H144.316V21.3446C144.316 24.0907 144.484 25.5281 145.042 26.5687H138.472C139.052 25.1634 139.188 23.018 139.188 19.6283ZM148.664 13.2992C151.528 13.2992 153.234 12.13 153.234 9.61986C153.234 7.10973 151.56 6.0692 148.664 6.0692H144.295V13.2992H148.664Z"
          fill="currentColor"
        />
        <path
          d="M184.272 13.9751C184.272 21.7093 180.124 26.5365 172.227 26.5365H163.32C163.91 25.1634 164.046 23.018 164.046 19.6283V8.34334C164.046 4.95358 163.91 2.8082 163.32 1.47804H172.227C180.124 1.47804 184.272 5.66157 184.272 13.9751ZM179.102 13.9751C179.102 8.74024 176.576 6.16577 172.227 6.16577H169.206V21.8488H172.227C176.576 21.8488 179.102 19.285 179.102 13.9751Z"
          fill="currentColor"
        />
        <path
          d="M223.754 25.4638L224.807 19.8642H225.039C225.549 20.6141 226.233 21.2239 227.03 21.6394C227.827 22.0549 228.713 22.2631 229.608 22.2457C231.651 22.2457 232.872 21.2373 232.872 19.596C232.872 17.9548 230.766 16.8177 228.787 15.2408C226.418 13.3636 224.249 11.7546 224.249 8.24683C224.249 4.08472 227.503 0.941682 232.504 0.941682C234.173 0.925395 235.825 1.29202 237.336 2.01439L236.483 7.14191H236.22C235.749 6.68294 235.192 6.32495 234.583 6.0891C233.973 5.85326 233.324 5.74438 232.672 5.76887C231.03 5.76887 229.577 6.63774 229.577 8.35407C229.577 10.0704 231.419 10.9608 233.662 12.6449C236.22 14.5865 238.2 16.2921 238.2 19.7784C238.2 23.962 234.809 27.0514 230.103 27.0514C227.884 27.1269 225.687 26.5776 223.754 25.4638Z"
          fill="currentColor"
        />
        <path
          d="M241.569 13.9751C241.569 6.13356 246.77 0.941686 254.035 0.941686C261.299 0.941686 266.564 6.13356 266.564 13.9751C266.564 21.8166 261.373 27.0406 254.035 27.0406C246.696 27.0406 241.569 21.8487 241.569 13.9751ZM261.236 13.9751C261.236 8.94406 258.278 5.83324 254.066 5.83324C249.855 5.83324 246.938 8.94406 246.938 13.9751C246.938 19.0061 249.834 22.1491 254.066 22.1491C258.299 22.1491 261.236 19.0275 261.236 13.9751Z"
          fill="currentColor"
        />
        <path
          d="M271.565 19.6283V8.34334C271.565 4.95358 271.428 2.8082 270.838 1.47804H276.229L285.936 16.2171L286.989 18.0299H287.179V6.70213C287.179 3.95599 287.021 2.50784 286.463 1.47804H293.064C292.443 2.8082 292.317 4.95358 292.317 8.34334V19.6283C292.317 23.018 292.443 25.1634 293.064 26.5365H287.705L277.945 12.4304L276.892 10.7248H276.692V21.3124C276.692 24.0586 276.861 25.496 277.419 26.5365H270.807C271.428 25.1956 271.565 23.018 271.565 19.6283Z"
          fill="currentColor"
        />
        <path
          d="M297.455 25.4638L298.508 19.8642H298.739C299.249 20.6141 299.933 21.2239 300.73 21.6394C301.528 22.0548 302.413 22.2631 303.309 22.2456C305.341 22.2456 306.562 21.2373 306.562 19.596C306.562 17.9548 304.456 16.8177 302.487 15.2408C300.118 13.3636 297.95 11.7546 297.95 8.24681C297.95 4.0847 301.203 0.941665 306.204 0.941665C307.873 0.927931 309.524 1.29443 311.037 2.01437L310.173 7.14189H309.91C309.439 6.68191 308.883 6.32329 308.273 6.08738C307.664 5.85147 307.013 5.74314 306.362 5.76886C304.719 5.76886 303.277 6.63772 303.277 8.35406C303.277 10.0704 305.109 10.9607 307.352 12.6449C309.91 14.5865 311.889 16.2921 311.889 19.7784C311.889 23.962 308.499 27.0514 303.793 27.0514C301.577 27.1288 299.383 26.5793 297.455 25.4638Z"
          fill="currentColor"
        />
        <path
          d="M194.948 17.4614H191.463L191.021 18.8237H189.189L192.284 9.16934H194.19L197.264 18.8237H195.39L194.948 17.4614ZM194.369 15.6377L193.221 12.0013L192.063 15.6377H194.369Z"
          fill="currentColor"
        />
        <path
          d="M207.656 9.22297V18.8773H205.856L201.897 12.602V18.8773H200.065V9.22297H201.897L205.856 15.4983V9.22297H207.656Z"
          fill="currentColor"
        />
        <path
          d="M219.122 14.0394C219.138 14.674 219.027 15.3052 218.795 15.8945C218.563 16.4837 218.216 17.0186 217.775 17.4665C217.333 17.9143 216.806 18.2657 216.227 18.4991C215.648 18.7324 215.028 18.8429 214.405 18.8237H211.246V9.16935H214.405C215.034 9.15271 215.66 9.26782 216.244 9.50752C216.828 9.74722 217.357 10.1064 217.799 10.5629C218.241 11.0194 218.587 11.5635 218.814 12.1615C219.042 12.7595 219.146 13.3986 219.122 14.0394ZM213.099 16.9894H214.394C215.165 16.9894 215.904 16.6775 216.449 16.1223C216.994 15.567 217.3 14.8139 217.3 14.0287C217.3 13.2435 216.994 12.4904 216.449 11.9352C215.904 11.38 215.165 11.0681 214.394 11.0681H213.099V16.9894Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M212.552 26.8905H195.064V25.024H212.552V26.8905Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M212.552 3.80579H195.064V1.93928H212.552V3.80579Z"
          fill="currentColor"
        />
        <path
          d="M0 11.5722H4.21145V12.6449H1.05286V13.7927H2.79011V14.8654H1.05286V16.0346H4.21145V17.1074H0V11.5722Z"
          fill="currentColor"
        />
        <path
          d="M7.37004 12.5055C6.85413 12.5055 6.52776 12.7843 6.52776 13.1598C6.53209 13.2694 6.56996 13.3749 6.63604 13.4614C6.70212 13.548 6.79313 13.6113 6.8963 13.6425C7.24374 13.7712 7.77012 13.6425 8.28602 13.8571C8.59704 13.9477 8.86953 14.1414 9.06025 14.4076C9.25096 14.6738 9.349 14.9973 9.33888 15.3267C9.33722 15.5777 9.28415 15.8256 9.18299 16.0545C9.08182 16.2834 8.93484 16.4881 8.75147 16.6558C8.56809 16.8234 8.35236 16.9502 8.11811 17.028C7.88385 17.1058 7.63622 17.1328 7.39111 17.1074C7.14705 17.134 6.90022 17.1095 6.66579 17.0353C6.43137 16.9612 6.2143 16.839 6.02793 16.6762C5.84156 16.5135 5.6898 16.3135 5.58202 16.0889C5.47424 15.8642 5.41272 15.6194 5.40119 15.3696H6.45405C6.45405 15.7987 6.8647 16.0991 7.41219 16.0991C7.95968 16.0991 8.3071 15.7879 8.3071 15.391C8.3071 15.275 8.27018 15.1621 8.20184 15.0692C8.1335 14.9764 8.03743 14.9087 7.92808 14.8762C7.58064 14.7474 7.01209 14.7903 6.5383 14.6509C6.22705 14.568 5.95293 14.3792 5.76144 14.1158C5.56995 13.8524 5.47257 13.5303 5.48544 13.2027C5.55352 12.7374 5.78336 12.3124 6.13313 12.0052C6.4829 11.6981 6.92934 11.5291 7.39111 11.5291C7.85289 11.5291 8.29933 11.6981 8.6491 12.0052C8.99887 12.3124 9.22871 12.7374 9.29679 13.2027H8.24393C8.2334 12.7629 7.91753 12.5055 7.37004 12.5055Z"
          fill="currentColor"
        />
        <path
          d="M11.9184 12.6128H10.3602V11.5401H14.5716V12.6128H13.0239V17.0323H11.971L11.9184 12.6128Z"
          fill="currentColor"
        />
        <path
          d="M15.7088 15.7772C15.793 15.7757 15.8767 15.7918 15.9547 15.8242C16.0327 15.8567 16.1034 15.905 16.1625 15.9663C16.2216 16.0275 16.2679 16.1003 16.2984 16.1803C16.329 16.2603 16.3433 16.3458 16.3405 16.4316C16.3434 16.517 16.3291 16.602 16.2984 16.6814C16.2677 16.7609 16.2213 16.833 16.162 16.8934C16.1028 16.9538 16.0319 17.0011 15.9539 17.0324C15.876 17.0636 15.7925 17.0782 15.7088 17.0752C15.6246 17.0782 15.5406 17.0636 15.4621 17.0324C15.3836 17.0012 15.3121 16.9542 15.252 16.8939C15.1919 16.8337 15.1445 16.7617 15.1126 16.6822C15.0807 16.6027 15.065 16.5175 15.0665 16.4316C15.0651 16.3453 15.0807 16.2595 15.1125 16.1795C15.1443 16.0995 15.1915 16.0267 15.2514 15.9657C15.3114 15.9046 15.3827 15.8565 15.4613 15.8241C15.5399 15.7917 15.6241 15.7758 15.7088 15.7772Z"
          fill="currentColor"
        />
        <path
          d="M318.754 12.7093L317.385 13.5567V12.4196L318.754 11.5722H319.807V17.0323H318.754V12.7093Z"
          fill="currentColor"
        />
        <path
          d="M325.334 13.3315C325.316 13.8604 325.152 14.3734 324.861 14.8118L323.524 17.0323H322.323L323.471 15.1658C323.227 15.1866 322.981 15.1554 322.749 15.0742C322.517 14.993 322.304 14.8636 322.124 14.6941C321.944 14.5247 321.8 14.3189 321.702 14.0898C321.604 13.8608 321.554 13.6135 321.555 13.3636C321.591 12.8722 321.807 12.4128 322.162 12.0775C322.516 11.7422 322.982 11.5559 323.466 11.5559C323.949 11.5559 324.415 11.7422 324.77 12.0775C325.124 12.4128 325.341 12.8722 325.377 13.3636L325.334 13.3315ZM323.429 14.1681C323.638 14.1681 323.839 14.0834 323.987 13.9325C324.135 13.7816 324.219 13.577 324.219 13.3636C324.219 13.1502 324.135 12.9456 323.987 12.7947C323.839 12.6438 323.638 12.5591 323.429 12.5591C323.318 12.5485 323.207 12.5618 323.101 12.5982C322.996 12.6345 322.9 12.6931 322.818 12.77C322.737 12.847 322.672 12.9406 322.629 13.0447C322.585 13.1488 322.563 13.2612 322.565 13.3743C322.565 13.4862 322.587 13.597 322.632 13.6994C322.676 13.8017 322.741 13.8934 322.823 13.9683C322.904 14.0433 323 14.0999 323.105 14.1343C323.209 14.1687 323.32 14.1802 323.429 14.1681Z"
          fill="currentColor"
        />
        <path
          d="M326.777 13.192C326.826 12.7287 327.041 12.3002 327.381 11.9887C327.721 11.6773 328.162 11.505 328.619 11.505C329.077 11.505 329.518 11.6773 329.858 11.9887C330.198 12.3002 330.413 12.7287 330.462 13.192C330.48 13.5493 330.48 13.9074 330.462 14.2647C330.48 14.622 330.48 14.9801 330.462 15.3374C330.413 15.8007 330.198 16.2293 329.858 16.5407C329.518 16.8521 329.077 17.0244 328.619 17.0244C328.162 17.0244 327.721 16.8521 327.381 16.5407C327.041 16.2293 326.826 15.8007 326.777 15.3374C326.777 15.0263 326.777 14.7689 326.777 14.2647C326.777 13.7605 326.756 13.5031 326.777 13.192ZM327.83 15.3374C327.83 15.5508 327.913 15.7554 328.061 15.9063C328.209 16.0572 328.41 16.142 328.619 16.142C328.829 16.142 329.03 16.0572 329.178 15.9063C329.326 15.7554 329.409 15.5508 329.409 15.3374C329.451 14.6587 329.451 13.978 329.409 13.2992C329.409 13.0859 329.326 12.8812 329.178 12.7304C329.03 12.5795 328.829 12.4947 328.619 12.4947C328.41 12.4947 328.209 12.5795 328.061 12.7304C327.913 12.8812 327.83 13.0859 327.83 13.2992C327.83 13.5031 327.83 13.7712 327.83 14.372C327.83 14.9727 327.809 15.1121 327.83 15.3159V15.3374Z"
          fill="currentColor"
        />
        <path
          d="M336 14.5972V15.67H335.21V17.0859H334.157V15.67H331.883V14.8332L334.063 11.6151H335.189V14.6509L336 14.5972ZM334.179 14.5972V12.8487L333.01 14.5865L334.179 14.5972Z"
          fill="currentColor"
        />
      </svg>
    </>
  );
};

export default SanfordAndSonsIcon;
