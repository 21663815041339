interface CloseMenuIconProps {
  className?: string;
}

const CloseMenuIcon: React.FC<CloseMenuIconProps> = (props) => {
  return (
    <>
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={props.className}
      >
        <rect x="4" y="4" width="24" height="24" rx="12" stroke="#172A4F" strokeWidth="2" />
        <path d="M20 12L12 20M20 20L12 12" stroke="#172A4F" strokeWidth="2" />
      </svg>
    </>
  );
};

export default CloseMenuIcon;
