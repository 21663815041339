import Cart from 'components/cart/Cart';
import Footer from 'components/layout/Footer';
import Header from 'components/layout/Header';
import { getDefaultHeadMetaData } from 'config/website';
import gsap from 'gsap';
import { ScrollSmoother } from 'gsap/dist/ScrollSmoother';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';
import { ScrollToPlugin } from 'gsap/dist/ScrollToPlugin';
import { SeoData, SiteSettingsData } from 'lib/contentful/types';
import Head from 'next/head';
import React from 'react';
import PreviewBanner from './PreviewBanner';
import NavigationQuickLinks from 'components/nav/NavigationQuickLinks';
import SubscribeForm from './SubscribeForm';
import ZendeskWidget from 'components/zendesk/ZendeskWidget';
import { BannersData } from '../lib/bigcommerce/types';

interface LayoutProps {
  preview?: boolean;
  metaTags?: Array<{ name: string; content: string }>;
  siteSettings: SiteSettingsData;
  siteBanners?: BannersData;
  seo: SeoData;
  children?: React.ReactNode;
}

const Layout: React.FC<LayoutProps> = (props) => {
  const { children, siteSettings, siteBanners, seo, metaTags, preview } = props;
  const defaultMetaData = getDefaultHeadMetaData();
  const currentHost: string = defaultMetaData.host;
  const currentMetaTitle = `${defaultMetaData.sitename} | ${
    seo.title || seo.title || defaultMetaData.title
  }`;
  const currentMetaDescription: string = seo.description || defaultMetaData.description;
  const currentShareImageUrl: string =
    seo.openGraphImageUrl || `${currentHost}${defaultMetaData.shareImage}`;

  gsap.registerPlugin(ScrollTrigger, ScrollSmoother, ScrollToPlugin);

  return (
    <>
      <Head>
        <title>{currentMetaTitle}</title>
        <meta name="viewport" content="width=device-width,minimal-ui,viewport-fit=cover" />
        <meta name="description" content={currentMetaDescription} />
        <meta name="title" content={currentMetaTitle} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={currentMetaTitle} />
        <meta name="twitter:description" content={currentMetaDescription} />
        <meta name="twitter:image" content={`${currentShareImageUrl}`} />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={currentMetaTitle} />
        <meta property="og:description" content={currentMetaDescription} />
        <meta property="og:site_name" content={currentMetaTitle} />
        <meta property="og:image" content={`${currentShareImageUrl}`} />
        {metaTags &&
          metaTags.map((x, index) => <meta key={index} name={x.name} content={x.content} />)}
      </Head>
      {preview && <PreviewBanner />}
      <Header
        menuItems={siteSettings.menuNavigation}
        logo={siteSettings.headerLogo}
        banners={siteBanners}
      />
      <main>{children}</main>
      <SubscribeForm />
      <Footer
        logo={siteSettings.footerLogo}
        address={siteSettings.address}
        email={siteSettings.email}
        facebookLink={siteSettings.facebook}
        instagramLink={siteSettings.instagram}
        copyright={siteSettings.copyright}
        navigationGroup={siteSettings.footerNavigationGroup}
      />
      <NavigationQuickLinks />
      <Cart />
      <ZendeskWidget />
    </>
  );
};

export default Layout;
