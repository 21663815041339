import React, { useEffect, useRef } from 'react';
import gsap from 'gsap';
import CloseModalIcon from 'components/icons/CloseModalIcon';
import { useRouter } from 'next/router';
interface ModalCartProps {
  onClose: () => void;
  children?: React.ReactNode;
}

const ModalCart: React.FC<ModalCartProps> = (props) => {
  const { onClose } = props;

  const router = useRouter();

  const containerRef = useRef(null);
  const contentRef = useRef(null);
  const buttonCloseRef = useRef(null);

  useEffect(() => {
    // const body = document.body;
    const container = containerRef.current;
    const content = contentRef.current;

    const buttonClose = buttonCloseRef.current;

    gsap.set(container, { display: 'block', pointerEvents: 'auto' });

    const delay = 0;

    gsap.fromTo(
      container,
      { opacity: 1, x: '110%' },
      { x: '0%', opacity: 1, duration: 0.6, delay: delay, ease: 'power3.inOut' }
    );

    gsap.fromTo(
      content,
      { opacity: 0 },
      { opacity: 1, duration: 0.6, delay: delay + 0.3, ease: 'power3.inOut' }
    );

    // gsap.set(body, { overflowY: 'hidden' });

    const closeModal = () => {
      buttonClose.removeEventListener('click', closeModal);

      gsap.fromTo(
        content,
        { opacity: 1 },
        { opacity: 0, duration: 0.6, delay: delay, ease: 'power3.out' }
      );

      gsap.to(container, {
        x: '100%',
        duration: 0.6,
        delay: delay + 0.3,
        ease: 'power2.in',
        onComplete: () => {
          onClose();
        },
      });
    };

    buttonClose.addEventListener('click', closeModal);

    const handleRouteChange = () => {
      closeModal();
    };

    router.events.on('routeChangeStart', handleRouteChange);

    return () => {
      buttonClose.removeEventListener('click', closeModal);
      router.events.off('routeChangeStart', handleRouteChange);
    };
  }, [router.events]);

  return (
    <>
      <div className="fixed inset-0 z-40 text-sf-blue-700 md:left-auto md:w-full md:max-w-[496px]">
        <div ref={containerRef} className="absolute inset-0 hidden">
          <div className="absolute inset-0">
            <div className="cart-shadow absolute inset-0 bg-white md:rounded-bl-lg md:rounded-tl-lg"></div>
          </div>
          <div ref={contentRef} className="absolute inset-0 flex flex-col overflow-hidden">
            <div className="relative flex h-full w-full flex-col">
              <div className="relative flex w-full items-center justify-between px-6 py-6 text-sf-blue-700">
                <div className="flex items-center justify-between">
                  <h4 className="text-3xl uppercase leading-none">Your cart</h4>
                </div>
                <div className="">
                  <button ref={buttonCloseRef}>
                    <CloseModalIcon></CloseModalIcon>
                  </button>
                </div>
              </div>
              <div className="relative flex flex-1 text-sf-blue-700">{props.children}</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ModalCart;
