import ButtonCart from 'components/ui/ButtonCart';
import ZendeskButton from 'components/zendesk/ZendeskButton';
import gsap from 'gsap';
import { useEffect, useRef } from 'react';

const NavigationQuickLinks: React.FC = () => {
  const buttonChatRef = useRef(null);
  const buttonCartRef = useRef(null);

  const isVisible = useRef(false);
  const resetButtonsWhenScrollUp = false;

  useEffect(() => {
    // to renable this effect remove the return and add the opacity-0 class to the buttons again.
    return;
    const buttons = [buttonChatRef.current, buttonCartRef.current];

    gsap.set(buttons, { opacity: 0, scale: 0.8, y: 0, pointerEvents: 'none' });

    const showButtons = () => {
      if (!isVisible.current) {
        isVisible.current = true;

        gsap.killTweensOf(buttons);

        gsap.set(buttons, { pointerEvents: 'auto' });
        gsap.to(buttons, { scale: 1, opacity: 1, duration: 0.6, ease: 'power3.out', stagger: 0.1 });
      }
    };

    const hideButtons = () => {
      if (isVisible.current && resetButtonsWhenScrollUp) {
        isVisible.current = false;

        gsap.set(buttons, { pointerEvents: 'none' });
        gsap.to(buttons, { scale: 0.8, opacity: 0, duration: 0.3, ease: 'power3.in' });
      }
    };

    const onScroll = () => {
      const scrollPosition = window.scrollY;
      if (scrollPosition > 10) {
        showButtons();
      } else {
        hideButtons();
      }
    };

    onScroll();
    window.addEventListener('scroll', onScroll);

    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, []);

  return (
    <>
      <div
        className="pointer-events-none fixed bottom-0 left-0 right-0 px-6 pb-6 text-sf-blue-700 print:hidden"
        style={{ zIndex: 1 }}
      >
        <div className="relative flex w-full items-center justify-between">
          <div ref={buttonChatRef} className="gpu">
            <ZendeskButton />
          </div>
          <div ref={buttonCartRef} className="gpu">
            <ButtonCart />
          </div>
        </div>
      </div>
    </>
  );
};

export default NavigationQuickLinks;
