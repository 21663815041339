import React from 'react';
import ChatIcon from 'components/icons/ChatIcon';
import { useZendeskStore } from 'store/zendeskStore';

const ZendeskButton: React.FC = () => {
  const zendesk = useZendeskStore();

  const onClick = () => zendesk.setOpen(true);
  if (!zendesk.isReady || zendesk.isOpen) return null;

  return (
    <div className="relative" onClick={onClick}>
      <button className="pointer-events-auto">
        <div className="navigation-quick-link-shadow relative rounded-full">
          <ChatIcon />
        </div>
      </button>
    </div>
  );
};

export default ZendeskButton;
