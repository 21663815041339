import { NavigationItemData } from 'lib/contentful/types';
import { useEffect, useRef } from 'react';
import Img from 'components/Img';
import gsap from 'gsap';

interface NavigationTopSecondaryMenuImageProps {
  currentNavigationItem: NavigationItemData;
}

const NavigationTopSecondaryMenuImage: React.FC<NavigationTopSecondaryMenuImageProps> = (props) => {
  const { currentNavigationItem } = props;
  const containerRef = useRef(null);
  const imageRef = useRef(null);

  useEffect(() => {
    const container = containerRef.current;
    const image = imageRef.current;

    gsap.fromTo(
      container,
      { x: '25%', opacity: 1 },
      { x: '0%', opacity: 1, duration: 1, ease: 'power3.out' }
    );

    gsap.fromTo(
      image,
      { x: '-100%', opacity: 1 },
      { x: '0%', opacity: 1, duration: 1, delay: 0, ease: 'power3.out' }
    );

    return () => {
      gsap.killTweensOf([container, image]);
      gsap.set(container, { opacity: 0 });
    };
  }, [currentNavigationItem]);

  return (
    <>
      <div>
        <div className="overflow-hidden">
          <div
            ref={containerRef}
            className="relative h-[246px] w-[246px] overflow-hidden rounded-md opacity-0"
          >
            <div ref={imageRef} className="relative opacity-0">
              <Img width={246} height={246} {...currentNavigationItem.subMenuImage}></Img>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NavigationTopSecondaryMenuImage;
