import React from 'react';
import { useNavStore } from 'store/navStore';
import { BannersData } from 'lib/bigcommerce/types';
import { NavigationItemData, SiteSettingsData } from 'lib/contentful/types';
import NavigationTopMenu from 'components/nav/NavigationTopMenu';
import NavigationSideMenu from './NavigationSideMenu';

interface NavigationBarProps {
  menuItems: Array<NavigationItemData>;
  banners?: BannersData;
  logo?: SiteSettingsData['headerLogo'];
}

const NavigationBar: React.FC<NavigationBarProps> = (props) => {
  const { menuItems, banners, logo } = props;
  const { sideNav } = useNavStore();

  return (
    <>
      <NavigationTopMenu menuItems={menuItems} logo={logo} banners={banners}></NavigationTopMenu>
      {sideNav && (
        <>
          <NavigationSideMenu menuItems={menuItems} />
        </>
      )}
    </>
  );
};

export default NavigationBar;
