import { useEffect, useRef, useState } from 'react';
import gsap from 'gsap';
import { NavigationItemData } from 'lib/contentful/types';
import MenuIcon from 'components/icons/MenuIcon';
import CloseMenuIcon from 'components/icons/CloseMenuIcon';
import SanfordAndSonsIcon from 'components/icons/SanfordAndSonsIcon';
import NavigationSideMenuItem from 'components/nav/NavigationSideMenuItem';
import NextLink from 'next/link';
import { useNavStore } from 'store/navStore';
import Link, { resolveNavigationPageUrl } from 'components/Link';
import NavigationSideSecondaryMenu from 'components/nav/NavigationSideSecondaryMenu';
import { useRouter } from 'next/router';
import NavigationSideMenuSearch from 'components/nav/NavigationSideMenuSearch';
import { ACCOUNT_NAVIGATION } from 'config/const';
import { useCartStore } from 'store/cartStore';
import { useIsUserLoggedIn } from 'hooks/useIsUserLoggedIn';

interface NavigationSideMenuProps {
  menuItems: Array<NavigationItemData>;
}

const NavigationSideMenu: React.FC<NavigationSideMenuProps> = (props) => {
  const { menuItems } = props;
  const router = useRouter();
  const { toggleCart } = useCartStore();

  const [subItems, setSubItems] = useState<NavigationItemData[] | null>(null);

  const accountItems = ACCOUNT_NAVIGATION;

  const containerRef = useRef(null);
  const backgroundRef = useRef(null);

  const menuContainerRef = useRef(null);
  const subItemsContainerRef = useRef(null);
  const primaryMenuContainerRef = useRef(null);

  const buttonCloseRef = useRef(null);

  const { toggleSideNav } = useNavStore();
  const loggedIn = useIsUserLoggedIn();

  const onNavigationItemClick = (activeSubItems: NavigationItemData[]) => {
    setSubItems(activeSubItems);
  };

  const onSecondaryNavigationClose = () => {
    setSubItems(null);
  };

  useEffect(() => {
    const body = document.body;
    const container = containerRef.current;
    const background = backgroundRef.current;

    const menuContainer = menuContainerRef.current;
    const buttonClose = buttonCloseRef.current;

    gsap.set(container, { display: 'block', pointerEvents: 'auto' });

    const delay = 0;

    gsap.fromTo(
      background,
      { opacity: 1, x: '-100%' },
      { x: '0%', opacity: 1, duration: 0.6, delay: delay, ease: 'power3.out' }
    );
    gsap.fromTo(
      menuContainer,
      { opacity: 0 },
      { opacity: 1, duration: 0.6, delay: delay + 0.5, ease: 'power3.out' }
    );

    gsap.set(body, { overflowY: 'hidden' });

    const closeMenu = () => {
      buttonClose.removeEventListener('click', closeMenu);

      gsap.set(container, { pointerEvents: 'none' });
      gsap.to(menuContainer, { opacity: 0, duration: 0.6, delay: delay + 0.2, ease: 'power3.out' });
      gsap.to(background, {
        x: '-100%',
        duration: 0.6,
        delay: delay + 0.6,
        ease: 'power2.in',
        onComplete: () => {
          toggleSideNav(false);
        },
      });
    };

    const handleRouteChange = () => {
      closeMenu();
    };

    router.events.on('routeChangeStart', handleRouteChange);
    buttonClose.addEventListener('click', closeMenu);

    return () => {
      buttonClose.removeEventListener('click', closeMenu);
      router.events.off('routeChangeStart', handleRouteChange);
      gsap.set(body, { overflowY: 'auto' });
    };
  }, [toggleSideNav, router.events]);

  useEffect(() => {
    const subItemsContainer = subItemsContainerRef.current;

    const currentSubItens = subItems || [];

    if (currentSubItens.length > 0) {
      gsap.to(subItemsContainer, { x: '0', duration: 0.3, ease: 'power3.out' });
    } else {
    }

    return () => {};
  }, [subItems]);

  return (
    <>
      <div className="fixed inset-0 z-40 overflow-hidden lg:hidden">
        <div ref={containerRef} className="absolute inset-0 hidden">
          <div className="absolute inset-0">
            <div ref={backgroundRef} className="absolute inset-0 bg-white"></div>
          </div>
          <div ref={menuContainerRef} className="absolute inset-0 flex flex-col overflow-hidden">
            <div className="relative flex h-full w-full flex-col">
              <div className="relative flex w-full items-center justify-between px-6 py-6 text-sf-blue-700 lg:px-6 lg:py-14">
                <div className="lg:hidden">
                  <div className="invisible">
                    <MenuIcon></MenuIcon>
                  </div>
                </div>
                <div className="flex items-center justify-center lg:justify-start">
                  <div className="w-full">
                    <NextLink href={'/'} passHref>
                      <a target={'_self'}>
                        <SanfordAndSonsIcon className="h-[14px] w-auto lg:h-[19px] xl:h-[26px]"></SanfordAndSonsIcon>
                      </a>
                    </NextLink>
                  </div>
                </div>
                <div className="flex items-center justify-center">
                  <button ref={buttonCloseRef} className="lg:hidden">
                    <CloseMenuIcon></CloseMenuIcon>
                  </button>
                </div>
              </div>
              <div className="relative w-full px-6">
                <NavigationSideMenuSearch />
              </div>
              <div className="relative flex flex-1 text-sf-blue-700">
                <div className="relative h-full w-full">
                  <div ref={primaryMenuContainerRef} className="absolute inset-0 overflow-y-scroll">
                    <div className="relative">
                      <ul className="relative">
                        {menuItems.map((item) => {
                          const { href } = resolveNavigationPageUrl(item);

                          return (
                            <NavigationSideMenuItem
                              key={item.id}
                              href={href}
                              currentItem={item}
                              onForward={onNavigationItemClick}
                            >
                              <Link {...item.link} className="inline-block w-full" />
                            </NavigationSideMenuItem>
                          );
                        })}
                      </ul>
                    </div>
                    <div className="relative w-full">
                      {accountItems.map((accountItemGroup, i) => (
                        <div key={i} className="relative w-full">
                          <div className="relative w-full px-6">
                            <div className="relative h-[2px] w-full bg-sf-blue-700 opacity-5"></div>
                          </div>
                          <div>
                            <ul className="relative">
                              {accountItemGroup
                                .filter(
                                  (item) =>
                                    item.type === 'default' ||
                                    item.type === (loggedIn ? 'logged' : 'logout')
                                )
                                .map((accountItem, j) => {
                                  return (
                                    <NavigationSideMenuItem
                                      key={`${i}_${j}`}
                                      href={accountItem.url}
                                    >
                                      <NextLink href={accountItem.url} passHref>
                                        <a href="_self">{accountItem.label}</a>
                                      </NextLink>
                                    </NavigationSideMenuItem>
                                  );
                                })}
                            </ul>
                          </div>
                        </div>
                      ))}
                      <div className="relative w-full">
                        <div className="relative w-full px-6">
                          <div className="relative h-[2px] w-full bg-sf-blue-700 opacity-5"></div>
                        </div>
                        <div>
                          <ul className="relative">
                            <NavigationSideMenuItem href={'/cart'}>
                              <button
                                className=" text-sm font-medium uppercase"
                                onClick={() => toggleCart(true)}
                              >
                                Cart
                              </button>
                            </NavigationSideMenuItem>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div ref={subItemsContainerRef} className="pointer-events-none absolute inset-0">
                    {subItems && (
                      <>
                        <NavigationSideSecondaryMenu
                          menuWrapper={primaryMenuContainerRef.current}
                          subItems={subItems}
                          onClose={onSecondaryNavigationClose}
                        />
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NavigationSideMenu;
