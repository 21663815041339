interface SanfordAndSonsIconProps {
  className?: string;
}

const SanfordAndSonsIcon: React.FC<SanfordAndSonsIconProps> = (props) => {
  return (
    <>
      <svg
        viewBox="0 0 1081 63"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={props.className}
      >
        <path
          d="M550.6 14.7001C548.1 13.3001 544.6 12.9001 538 12.9001H525.2V27.2001H535.6C540.1 27.2001 543.2 27.0001 545 26.3001V37.8001C543.2 37.1001 540.1 36.9001 535.6 36.9001H525.2V49.1001C525.2 55.7001 525.5 59.2001 527 61.7001H510.8C512.2 58.4001 512.6 53.2001 512.6 45.1001V18.1001C512.6 10.0001 512.3 4.8001 510.8 1.6001H550.6V14.7001Z"
          fill="white"
        />
        <path
          d="M576.3 45.2001C576.3 53.3001 576.6 58.6001 578.1 61.8001H562C563.4 58.6001 563.8 53.3001 563.8 45.2001V18.1001C563.8 10.0001 563.5 4.8001 562 1.6001H578.1C576.7 4.8001 576.3 10.0001 576.3 18.1001V45.2001Z"
          fill="white"
        />
        <path
          d="M687.8 45.2001C687.8 53.3001 688.1 58.6001 689.7 61.8001H673.5C674.9 59.3001 675.3 55.8001 675.3 49.2001V35.9001H650.9V49.3001C650.9 55.9001 651.3 59.4001 652.7 61.9001H636.5C637.9 59.4001 638.3 55.9001 638.3 49.3001V18.1001C638.3 10.0001 638 4.8001 636.5 1.6001H652.7C651.3 4.1001 650.9 7.6001 650.9 14.2001V24.8001H675.3V14.2001C675.3 7.6001 674.9 4.1001 673.5 1.6001H689.7C688.2 4.8001 687.8 10.0001 687.8 18.1001V45.2001Z"
          fill="white"
        />
        <path
          d="M763.2 45.2001C763.2 53.3001 763.5 58.6001 765 61.8001H748.8C750.2 59.3001 750.6 55.8001 750.6 49.2001L750.7 23.7001H750.5L734.9 47.7001H733.9L718.2 23.7001H718L718.1 49.2001C718.1 55.8001 718.5 59.3001 719.9 61.8001H703.7C705 59.1001 705.5 55.0001 705.5 48.7001V18.1001C705.5 10.0001 705.2 4.8001 703.7 1.6001H717L734.3 30.6001H734.6L752 1.6001H765C763.6 4.8001 763.2 10.0001 763.2 18.1001V45.2001Z"
          fill="white"
        />
        <path
          d="M774.3 31.6C774.3 12.8 787 0.300049 804.8 0.300049C822.7 0.300049 835.5 12.8 835.5 31.6C835.5 50.6 822.8 63 804.8 63C787 63 774.3 50.6 774.3 31.6ZM822.4 31.6C822.4 19.5 815.2 12 804.9 12C794.6 12 787.4 19.5 787.4 31.6C787.4 43.8 794.5 51.2001 804.9 51.2001C815.3 51.2001 822.4 43.8 822.4 31.6Z"
          fill="white"
        />
        <path
          d="M846.5 45.2V18.1C846.5 10 846.2 4.8 844.7 1.6H858L881.7 37L884.3 41.3H884.8L884.7 36.8L884.8 14.1C884.8 7.5 884.4 4 883 1.5H899.2C897.7 4.7 897.3 9.9 897.3 18V45.1C897.3 53.2 897.6 58.4 899.2 61.7H886.1L862.2 27.8L859.6 23.7H859.1L859.2 28V49.2C859.2 55.8 859.6 59.3 861 61.8H844.8C846.2 58.6 846.5 53.4 846.5 45.2Z"
          fill="white"
        />
        <path
          d="M964.7 30C964.1 31.8 963.8 35.0001 963.8 39.4001V53.5C958.2 59.6 950.1 63 940.7 63C923.7 63 908.5 52.1 908.5 31.6C908.5 12.1 922.3 0.300049 941.7 0.300049C949.3 0.300049 955.6 2.20005 960.9 5.00005L958.8 17.4H958.2C954.3 14 948.9 11.9 942.2 11.9C930.3 11.9 921.5 19.1 921.5 31.6C921.5 45.1 931.2 51.6 940.7 51.6C945.1 51.6 948.7 50.4 951.3 48.5V39.8C951.3 35.1 951.1 31.9 950.3 30H964.7Z"
          fill="white"
        />
        <path
          d="M1016.2 14.6001C1013.7 13.2001 1010.2 12.8001 1003.6 12.8001H990L989.9 26.5001H1003C1007.4 26.5001 1010.6 26.3001 1012.4 25.6001V37.1001C1010.6 36.4001 1007.4 36.2001 1003 36.2001H989.9L990 50.6001H1003.6C1010.2 50.6001 1013.7 50.2001 1016.2 48.8001V61.8001H975.6C977 58.5001 977.4 53.3001 977.4 45.2001V18.1001C977.4 10.0001 977.1 4.8001 975.6 1.6001H1016.2V14.6001Z"
          fill="white"
        />
        <path
          d="M1031.4 45.2001V18.1001C1031.4 10.0001 1031.1 4.8001 1029.6 1.6001H1056.3C1069.4 1.6001 1078.4 8.1001 1078.4 20.9001C1078.4 28.9001 1074.5 35.2001 1067.9 38.3001L1074.7 53.4001C1077.2 59.0001 1078.6 61.2001 1080.3 61.9001H1063.5C1064.5 60.5001 1064 58.6001 1062 53.9001L1056.4 40.9001H1044V49.4001C1044 56.0001 1044.4 59.5001 1045.8 62.0001H1029.7C1031.1 58.5001 1031.4 53.4001 1031.4 45.2001ZM1054.7 30.0001C1061.7 30.0001 1065.9 27.2001 1065.9 21.1001C1065.9 15.3001 1061.8 12.6001 1054.7 12.6001H1044V30.0001H1054.7Z"
          fill="white"
        />
        <path
          d="M589.9 59.1L590.9 45.2001H591.5C595.5 50.1001 602.3 51.5 605.5 51.5C610.4 51.5 614.6 49.5 614.6 44.3C614.6 40.1 608.2 37.4001 601.9 34.2001C595.5 30.8001 590.3 27.7 589.8 19.3C589.2 8.90005 597.9 0.300049 609.7 0.300049C615.2 0.300049 618.6 0.900049 623 3.30005L621.8 16.6L621.2 16.7001C618.2 13.0001 613.9 11.7 609.9 11.9C605.9 12.1 602.5 14.9 602.7 18.5C603 23.5 608.4 24.7 614.6 27.5C621.9 30.9 627.7 35.5 627.7 44.5C627.7 54.5 618.1 63 606.7 63C599.6 62.9 592.8 61 589.9 59.1Z"
          fill="white"
        />
        <path
          d="M81.3 61.6999C82.4 60.2999 82.4 58.5999 81.2 54.0999L80 49.7999H60L58.7 54.3999C57.6 58.5999 57.6 60.2999 58.6 61.5999H41.9C43.4 60.6999 44.1 58.6999 45.3 54.9999L62.5 1.3999H77.6L94.8 55.0999C96 58.7999 96.7 60.7999 98.2 61.5999H81.3V61.6999ZM77.3 40.0999L70.2 14.9999H70L62.8 40.0999H77.3Z"
          fill="white"
        />
        <path
          d="M105.2 45.0999V17.8999C105.2 9.79993 104.9 4.59993 103.4 1.39993H116.6L140.3 36.7999L142.9 41.0999H143.4L143.3 36.5999L143.4 13.8999C143.4 7.29993 143 3.79993 141.6 1.29993H157.8C156.3 4.49993 155.9 9.69993 155.9 17.7999V44.8999C155.9 52.9999 156.2 58.1999 157.8 61.4999H144.7L120.8 27.5999L118.2 23.4999H117.7L117.8 27.7999V48.9999C117.8 55.5999 118.2 59.0999 119.6 61.5999H103.4C104.8 58.3999 105.2 53.1999 105.2 45.0999Z"
          fill="white"
        />
        <path
          d="M210.6 14.5999C208.1 13.1999 204.6 12.7999 198 12.7999H185.2V27.0999H195.6C200.1 27.0999 203.2 26.8999 205 26.1999V37.6999C203.2 36.9999 200.1 36.7999 195.6 36.7999H185.2V48.9999C185.2 55.5999 185.5 59.0999 187 61.5999H170.8C172.2 58.2999 172.6 53.0999 172.6 44.9999V17.8999C172.6 9.7999 172.3 4.5999 170.8 1.3999H210.6V14.5999Z"
          fill="white"
        />
        <path
          d="M215.8 31.5C215.8 12.7 228.5 0.199951 246.3 0.199951C264.3 0.199951 277 12.7 277 31.5C277 50.5 264.3 62.9 246.3 62.9C228.5 62.9 215.8 50.4 215.8 31.5ZM263.9 31.5C263.9 19.4 256.7 11.9 246.4 11.9C236.1 11.9 228.9 19.4 228.9 31.5C228.9 43.7 236 51.0999 246.4 51.0999C256.7 51.0999 263.9 43.6 263.9 31.5Z"
          fill="white"
        />
        <path
          d="M287.2 45.0999V17.8999C287.2 9.7999 286.9 4.5999 285.4 1.3999H312.1C325.2 1.3999 334.2 7.8999 334.2 20.6999C334.2 28.6999 330.3 34.9999 323.7 38.0999L330.5 53.1999C333 58.7999 334.4 60.9999 336.1 61.6999H319.3C320.3 60.2999 319.8 58.3999 317.8 53.6999L312.2 40.6999H299.8V49.1999C299.8 55.7999 300.2 59.2999 301.6 61.7999H285.5C286.9 58.3999 287.2 53.1999 287.2 45.0999ZM310.5 29.8999C317.5 29.8999 321.7 27.0999 321.7 20.9999C321.7 15.1999 317.6 12.4999 310.5 12.4999H299.8V29.8999H310.5Z"
          fill="white"
        />
        <path
          d="M397.6 31.4999C397.6 50.0999 387.5 61.6999 368.1 61.6999H346.3C347.7 58.3999 348.1 53.1999 348.1 45.0999V17.8999C348.1 9.7999 347.8 4.5999 346.3 1.3999H368.1C387.4 1.3999 397.6 11.4999 397.6 31.4999ZM385 31.4999C385 18.8999 378.8 12.6999 368.2 12.6999H360.8V50.3999H368.2C378.8 50.3999 385 44.0999 385 31.4999Z"
          fill="white"
        />
        <path
          d="M0.300045 58.7L1.30004 44.8H1.90004C5.90004 49.7 12.7 51.1 15.9 51.1C20.8 51.1 25 49.1 25 43.9C25 39.7 18.6 37 12.3 33.8C5.80005 30.4 0.600045 27.3 0.100045 18.9C-0.499955 8.5 8.20004 0 20 0C25.5 0 28.9 0.6 33.3 3L32.1 16.3L31.5 16.4C28.5 12.7 24.2 11.4 20.2 11.6C16.2 11.8 12.8 14.6 13 18.2C13.3 23.2 18.7 24.4 24.9 27.2C32.2 30.6 38 35.2 38 44.2C38 54.2 28.4 62.7 17 62.7C10 62.6 3.20004 60.7 0.300045 58.7Z"
          fill="white"
        />
        <path
          d="M439.2 20.9H433L432.1 23.5H429.5L434.8 7.59998H437.5L442.8 23.5H440.1L439.2 20.9ZM438.4 18.4L436.1 11.5L433.8 18.4H438.4Z"
          fill="white"
        />
        <path
          d="M459.1 7.59998V23.5H456.5L449.1 12.2V23.5H446.5V7.59998H449.1L456.5 18.9V7.59998H459.1Z"
          fill="white"
        />
        <path
          d="M477.4 15.6C477.4 20.1 474.1 23.5 469.5 23.5H464.4V7.59998H469.5C474 7.59998 477.4 11.1 477.4 15.6ZM466.9 20.9H469.4C472.5 20.9 474.8 18.6 474.8 15.5C474.8 12.4 472.6 10.1 469.4 10.1H466.9V20.9Z"
          fill="white"
        />
        <path d="M477.9 2.09998H428.6V3.99998H477.9V2.09998Z" fill="white" />
        <path d="M477.9 27.2H428.6V29.1H477.9V27.2Z" fill="white" />
        <path
          d="M418.3 41.5C416 41.5 414.5 42.7 414.5 44.3C414.5 45.3 415.2 46.1 416.2 46.4C417.7 47 420.1 46.7 422.3 47.3C425.3 48.2 427.2 50.5 427.2 53.7C427.2 58.2 423.7 61.5 418.5 61.5C413.4 61.5 409.6 58.4 409.6 53.9H414.2C414.2 55.8 416 57.1 418.5 57.1C421 57.1 422.5 55.8 422.5 54C422.5 52.8 421.7 52.1 420.8 51.7C419.3 51.1 416.7 51.3 414.6 50.7C411.5 49.8 409.8 47.4 409.8 44.4C409.8 40.1 413.3 36.9 418.3 36.9C423.2 36.9 426.8 39.9 426.8 44.2H422.1C422.2 42.7 420.6 41.5 418.3 41.5Z"
          fill="white"
        />
        <path
          d="M442.1 37.1C449.3 37.1 454.4 42.5 454.4 49.4C454.4 56.3 449.2 61.7 442.1 61.7C434.9 61.7 429.8 56.3 429.8 49.4C429.8 42.5 434.9 37.1 442.1 37.1ZM442.1 57.1C446.6 57.1 449.7 53.8 449.7 49.4C449.7 45 446.6 41.7 442.1 41.7C437.7 41.7 434.5 45 434.5 49.4C434.5 53.8 437.6 57.1 442.1 57.1Z"
          fill="white"
        />
        <path
          d="M477.3 37.4V61.3H472.7L462.7 45.7V61.3H458.1V37.4H462.7L472.7 53V37.4H477.3Z"
          fill="white"
        />
        <path
          d="M489.6 41.5C487.3 41.5 485.8 42.7 485.8 44.3C485.8 45.3 486.5 46.1 487.5 46.4C489 47 491.4 46.7 493.6 47.3C496.6 48.2 498.5 50.5 498.5 53.7C498.5 58.2 495 61.5 489.8 61.5C484.7 61.5 480.9 58.4 480.9 53.9H485.5C485.5 55.8 487.3 57.1 489.8 57.1C492.3 57.1 493.8 55.8 493.8 54C493.8 52.8 493 52.1 492.1 51.7C490.6 51.1 488 51.3 485.9 50.7C482.8 49.8 481.1 47.4 481.1 44.4C481.1 40.1 484.6 36.9 489.6 36.9C494.5 36.9 498.1 39.9 498.1 44.2H493.4C493.4 42.7 491.8 41.5 489.6 41.5Z"
          fill="white"
        />
      </svg>
    </>
  );
};

export default SanfordAndSonsIcon;
