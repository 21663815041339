import gsap from 'gsap';
import { NavigationItemData, SiteSettingsData } from 'lib/contentful/types';
import NavigationTopMenuItem from 'components/nav/NavigationTopMenuItem';
import Link, { resolveNavigationPageUrl } from 'components/Link';
import React, { useEffect, useRef, useState } from 'react';
import { ACCOUNT_NAVIGATION } from 'config/const';
import { useNavStore } from 'store/navStore';
import MenuIcon from 'components/icons/MenuIcon';
import NextLink from 'next/link';
import ProfileIcon from 'components/icons/ProfileIcon';
import SearchIcon from 'components/icons/SearchIcon';
import NavigationTopSecondaryMenu from './NavigationTopSecondaryMenu';
import { useRouter } from 'next/router';
import classnames from 'utils/classnames';
import { useIsUserLoggedIn } from 'hooks/useIsUserLoggedIn';
import { BannersData } from 'lib/bigcommerce/types';
import Banner from 'components/banner/Banner';
import Image from 'next/image';
import SanfordAndSonsIcon from 'components/icons/SanfordAndSonsIcon';
import SanfordAndSonsNewIcon from 'components/icons/SanfordAndSonsFishmongersIcon';
import SanfordAndSonsFishmongersIcon from 'components/icons/SanfordAndSonsFishmongersIcon';

interface NavigationTopMenuProps {
  menuItems: Array<NavigationItemData>;
  banners?: BannersData;
  logo?: SiteSettingsData['headerLogo'];
}

const NavigationTopMenu: React.FC<NavigationTopMenuProps> = (props) => {
  const { menuItems, banners, logo } = props;

  const [currentNavigationItem, setCurrentNavigationItem] = useState<NavigationItemData | null>(
    null
  );

  const router = useRouter();

  const containerRef = useRef(null);
  const backgroundRef = useRef(null);
  const navRef = useRef(null);

  const isBackgroundAvailable = useRef(false);

  const accountItems = ACCOUNT_NAVIGATION;
  const loggedIn = useIsUserLoggedIn();
  const { toggleSideNav } = useNavStore();

  const onNavigationItemMouseEnter = (navigationItem: NavigationItemData) => {
    setCurrentNavigationItem(navigationItem);
  };
  const onNavigationItemMouseLeave = () => {
    setCurrentNavigationItem(null);
  };

  useEffect(() => {
    const container = containerRef.current;
    const background = backgroundRef.current;
    const nav = navRef.current;

    const showBackgroundBar = () => {
      if (!isBackgroundAvailable.current) {
        isBackgroundAvailable.current = true;
        gsap.killTweensOf([background, nav]);
        gsap.to(background, { opacity: 1, duration: 0.15, ease: 'power3.out' });
        gsap.to(nav, { height: 80, duration: 0.6, ease: 'power3.out' });
      }
    };

    const hideBackgroundBar = () => {
      if (isBackgroundAvailable.current) {
        isBackgroundAvailable.current = false;
        gsap.killTweensOf([background, nav]);

        const delay = 0;

        gsap.to(nav, { height: 152, duration: 0.6, ease: 'power3.out', delay: delay });
        gsap.to(background, {
          opacity: 0,
          duration: 0.3,
          ease: 'power3.out',
          delay: delay,
        });
      }
    };

    const onScroll = () => {
      const scrollPosition = window.scrollY;
      if (scrollPosition > 0) {
        showBackgroundBar();
      } else {
        hideBackgroundBar();
      }
    };

    onScroll();
    window.addEventListener('scroll', onScroll);
    container.addEventListener('mouseleave', onNavigationItemMouseLeave);

    const handleRouteChange = () => {
      onNavigationItemMouseLeave();
    };

    router.events.on('routeChangeStart', handleRouteChange);

    return () => {
      window.removeEventListener('scroll', onScroll);
      container.removeEventListener('mouseleave', onNavigationItemMouseLeave);
      router.events.off('routeChangeStart', handleRouteChange);
    };
  }, [router.events]);

  return (
    <>
      <div
        className={classnames('relative h-[80px] w-full lg:h-[152px] print:hidden', {
          hidden: router.asPath === '/',
        })}
      ></div>
      <div ref={containerRef} className="fixed left-0 right-0 top-0 z-30 print:relative">
        {banners && banners?.map((banner) => <Banner key={banner.id} {...banner} />)}
        <div className="relative z-30 w-full">
          <div
            ref={backgroundRef}
            className="gpu absolute inset-0 -translate-y-full transform bg-white opacity-0"
          ></div>
          <div
            ref={navRef}
            className="gpu relative flex h-[80px] max-h-[80px] w-full items-center justify-between px-6 text-sf-blue-700 lg:h-[152px] lg:max-h-[152px] lg:px-6"
          >
            <div className="lg:hidden print:hidden">
              <button
                className="flex items-center justify-center"
                onClick={() => {
                  toggleSideNav(true);
                }}
              >
                <MenuIcon></MenuIcon>
              </button>
            </div>

            {/*<div className="flex h-full w-full items-center justify-center sm:max-w-md md:max-w-md lg:max-w-lg lg:justify-start xl:max-w-lg">*/}
            <div className="flex items-center justify-center lg:justify-start">
              <div className="w-full">
                <NextLink href={'/'} passHref>
                  <a target={'_self'}>
                    <SanfordAndSonsFishmongersIcon className="h-[14px] w-auto lg:-mt-2 lg:h-[19px] xl:h-[26px]"></SanfordAndSonsFishmongersIcon>
                  </a>
                </NextLink>
              </div>
            </div>
            <div className="hidden h-full w-full lg:block print:hidden">
              <nav className="flex h-full w-full items-center justify-center">
                <div className="relative h-full">
                  <ul className="relative flex h-full items-center justify-center px-6">
                    {menuItems?.map((item) => {
                      const { href } = resolveNavigationPageUrl(item);

                      return (
                        <NavigationTopMenuItem
                          href={href}
                          key={item.id}
                          item={item}
                          onMouseEnter={onNavigationItemMouseEnter}
                        >
                          <Link
                            {...item.link}
                            className="flex w-full flex-nowrap px-3 text-sm font-medium uppercase xl:px-5"
                          />
                        </NavigationTopMenuItem>
                      );
                    })}
                  </ul>
                </div>
              </nav>
            </div>
            <div className="flex items-center justify-center print:hidden">
              <div className="lg:hidden">
                <NextLink href={'/my-account/details'} passHref>
                  <a href="_self">
                    <ProfileIcon></ProfileIcon>
                  </a>
                </NextLink>
              </div>
              <div className="hidden items-center justify-center lg:flex">
                <ul className="relative flex w-full items-center justify-center pr-4 xl:pr-5">
                  <li>
                    <NextLink href={'/search'} passHref>
                      <a href="_self">
                        <SearchIcon />
                      </a>
                    </NextLink>
                  </li>
                </ul>
                {accountItems.map((accountItemGroup, i) => {
                  const filteredAccountGroup = accountItemGroup.filter((item) =>
                    loggedIn
                      ? item.type === 'logged'
                      : item.url != '/signup' && item.type === 'logout'
                  );

                  if (filteredAccountGroup.length <= 0) {
                    return;
                  }

                  return (
                    <ul
                      key={i}
                      className="relative flex w-full items-center justify-center gap-x-4 xl:gap-x-5"
                    >
                      {filteredAccountGroup.map((accountItem, j) => {
                        return (
                          <NavigationTopMenuItem
                            key={`${i}_${j}`}
                            href={accountItem.url}
                            useVerticalBar={
                              filteredAccountGroup.length <= 1 ? true : j === 0 ? false : true
                            }
                          >
                            <div className="relative flex w-full items-center">
                              <div>
                                <NextLink href={accountItem.url} passHref>
                                  <a
                                    href="_self"
                                    className="whitespace-nowrap text-sm font-medium uppercase"
                                  >
                                    {accountItem.label}
                                  </a>
                                </NextLink>
                              </div>
                            </div>
                          </NavigationTopMenuItem>
                        );
                      })}
                    </ul>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
        <div className="relative z-20 w-full">
          {currentNavigationItem && currentNavigationItem.subItems.length > 0 && (
            <>
              <NavigationTopSecondaryMenu currentNavigationItem={currentNavigationItem} />
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default NavigationTopMenu;
