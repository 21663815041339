import React from 'react';
import { BannersData } from 'lib/bigcommerce/types';
import { NavigationItemData, SiteSettingsData } from 'lib/contentful/types';
import NavigationBar from 'components/nav/NavigationBar';

interface HeaderProps {
  menuItems: Array<NavigationItemData>;
  banners?: BannersData;
  logo?: SiteSettingsData['headerLogo'];
}

const Header: React.FC<HeaderProps> = (props) => {
  const { menuItems, banners, logo } = props;

  return <NavigationBar menuItems={menuItems} logo={logo} banners={banners}></NavigationBar>;
};

export default Header;
