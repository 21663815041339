import UnitArrowDownIcon from 'components/icons/UnitArrowDownIcon';
import UnitArrowUpIcon from 'components/icons/UnitArrowUpIcon';

interface QuantityProps {
  quantity: number;
  onChange: (newQuantity: number) => void;
}

const Quantity: React.FC<QuantityProps> = (props) => {
  const { quantity, onChange } = props;

  const minQuantity = 1;
  const maxQuantity = 50;

  return (
    <>
      <div className="relative flex w-full items-center justify-center rounded-md border border-sf-blue-700 md:max-w-[200px]">
        <div>
          <button
            className="px-7 py-6"
            onClick={() => {
              onChange(Math.max(quantity - 1, minQuantity));
            }}
          >
            <UnitArrowDownIcon />
          </button>
        </div>
        <div className="flex w-full items-center justify-center">
          <div>
            <span className="text-sm font-medium">
              {quantity} unit{quantity > 1 ? 's' : ''}
            </span>
          </div>
        </div>
        <div>
          <button
            className="px-7 py-6"
            onClick={() => onChange(Math.min(quantity + 1, maxQuantity))}
          >
            <UnitArrowUpIcon />
          </button>
        </div>
      </div>
    </>
  );
};
export default Quantity;
