import CartIcon from 'components/icons/CartIcon';
import { useCart } from 'hooks/useCart';
import track from 'lib/tracking';
import { useCartStore } from 'store/cartStore';
import { getNotificationNumber } from 'utils/notification';

const ButtonCart: React.FC = () => {
  const { toggleCart } = useCartStore();
  const { cart } = useCart();

  return (
    <div className="relative">
      <button
        className="pointer-events-auto relative"
        onClick={() => {
          toggleCart(true);
          track.viewCart(cart);
        }}
      >
        <div className="navigation-quick-link-shadow relative rounded-full">
          <CartIcon className=""></CartIcon>
        </div>
        {cart?.lineItems?.length > 0 && (
          <>
            <div className="absolute -right-1 -top-1 text-xs font-medium text-white">
              <div className="relative h-[20px] w-[20px] rounded-full bg-sf-gold-500">
                <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transform pt-0.5">
                  {getNotificationNumber(cart?.lineItems?.length)}
                </div>
              </div>
            </div>
          </>
        )}
      </button>
    </div>
  );
};
export default ButtonCart;
