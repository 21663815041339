import classnames from 'utils/classnames';

interface ContainerProps {
  className?: string;
  children?: React.ReactNode;
}

const Container: React.FC<ContainerProps> = (props) => {
  return (
    <div className={classnames('relative w-full px-6', props.className)}>
      <div className="relative mx-auto w-full max-w-[1344px]">{props.children}</div>
    </div>
  );
};

export default Container;
