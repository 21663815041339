import * as yup from 'yup';
import {
  SFAddUpdateCustomerAddressRequest,
  SFForgotPasswordRequest,
  SFLoginRequest,
  SFSignupRequest,
  SFSubmitResetPasswordRequest,
  SFSubscribeRequest,
  SFUpdateCustomerDetailsRequest,
  SFUpdateCustomerPasswordRequest,
} from '.';
import { isMatch } from 'date-fns';
import { newZealandStates } from 'containers/CustomerAddressForm';

export const loginSchema: yup.SchemaOf<Partial<SFLoginRequest>> = yup.object({
  email: yup.string().email('Email Please Enter a valid email').required('Email required'),
  password: yup.string().required('Password required'),
});

export const signupSchema: yup.SchemaOf<Partial<SFSignupRequest>> = yup.object({
  email: yup.string().email('Invalid Email').required('Email is required'),
  password: yup
    .string()
    .required('Password Required')
    .test(
      'len',
      'Passwords must be at least 7 characters and contain both alphabetic and numeric characters.',
      (val) => val && val.length >= 7 && /\d/.test(val) && /[a-zA-Z]/g.test(val)
    ),
  firstName: yup.string().required('First Name Required'),
  offersAccepted: yup.boolean().default(false),
  lastName: yup.string().required('Last name Required'),
  phone: yup.string(),
});

export const forgotPasswordSchema: yup.SchemaOf<Partial<SFForgotPasswordRequest>> = yup.object({
  email: yup.string().email('Email Please Enter a valid email').required('Email required'),
});

export const subscribeSchema: yup.SchemaOf<Partial<SFSubscribeRequest>> = yup.object({
  email: yup.string().email('Please enter a valid email address.').required('Email required'),
  firstName: yup.string().required('First name required'),
});

export const customerDetailsSchema: yup.SchemaOf<Partial<SFUpdateCustomerDetailsRequest>> =
  yup.object({
    email: yup.string().email('Invalid Email').required('Email is required'),
    firstName: yup.string().required('First Name Required'),
    lastName: yup.string().required('Last name Required'),
    phone: yup.string(),
    DOB: yup
      .object({
        day: yup
          .number()
          .nullable()
          .transform((curr, orig) => (orig === '' ? null : curr))
          .min(1)
          .max(31),
        month: yup
          .number()
          .nullable()
          .transform((curr, orig) => (orig === '' ? null : curr))
          .min(1)
          .max(12),
        year: yup
          .number()
          .nullable()
          .transform((curr, orig) => (orig === '' ? null : curr))
          .min(1900)
          .max(new Date().getFullYear()),
      })
      .test('valid-format', (values) => {
        const { day, month, year } = values;
        const isEmpty = !day && !month && !year;
        const isSome = !!(day || month || year);
        const isComplete = day && month && year;

        if (!isEmpty && isComplete) {
          return isMatch(`${year}-${month}-${day}`, 'yyyy-MM-dd');
        }
        return true;
      }),
  });

export const updateCustomerPasswordSchema: yup.SchemaOf<Partial<SFUpdateCustomerPasswordRequest>> =
  yup.object({
    password: yup.string().required('Current Password Required'),
    newPassword: yup
      .string()
      .required('New Password Required')
      .test(
        'len',
        'Passwords must be at least 7 characters and contain both alphabetic and numeric characters.',
        (val) => val && val.length >= 7 && /\d/.test(val) && /[a-zA-Z]/g.test(val)
      ),
    newPasswordRepeat: yup
      .string()
      .required()
      .oneOf([yup.ref('newPassword')], 'Confirm password does not match'),
  });

export const customerAddressSchema: yup.SchemaOf<Partial<SFAddUpdateCustomerAddressRequest>> =
  yup.object({
    firstName: yup.string().required('First Name required'),
    lastName: yup.string().required('Last Name required'),
    company: yup.string(),
    phone: yup.string(),
    address1: yup.string().required('Address required'),
    address2: yup.string().required('Suburb required'),
    city: yup.string().required('City required'),
    countryCode: yup.string().required('Country required'),
    stateOrProvince: yup
      .string()
      .oneOf(
        newZealandStates.map((s) => s.value),
        'Please choose a region'
      )
      .required('Region required'),
    postalCode: yup.string(),
  });

export const resetPasswordSchema: yup.SchemaOf<Partial<SFSubmitResetPasswordRequest>> = yup.object({
  token: yup.string().required('Token Required'),
  password: yup
    .string()
    .required('Password Required')
    .test(
      'len',
      'Passwords must be at least 7 characters and contain both alphabetic and numeric characters.',
      (val) => val && val.length >= 7 && /\d/.test(val) && /[a-zA-Z]/g.test(val)
    ),
  passwordRepeat: yup
    .string()
    .required()
    .oneOf([yup.ref('password')], 'Confirm password does not match'),
});
