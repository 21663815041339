import { NavigationItemData } from 'lib/contentful/types';
import Link, { resolveNavigationPageUrl } from 'components/Link';
import NavigationSideMenuItem from './NavigationSideMenuItem';
import { useEffect, useRef } from 'react';
import gsap from 'gsap';

interface NavigationSideSecondaryMenuProps {
  menuWrapper: HTMLDivElement;
  subItems: Array<NavigationItemData>;
  onClose: () => void;
}

const NavigationSideSecondaryMenu: React.FC<NavigationSideSecondaryMenuProps> = (props) => {
  const { menuWrapper, onClose, subItems } = props;

  const containerRef = useRef(null);

  const onClickBackButton = () => {
    const wrapper = menuWrapper;
    const container = containerRef.current;

    gsap.to(wrapper, { x: 0, opacity: 1, duration: 0.5, ease: 'power3.out' });

    gsap.to(container, {
      x: 100,
      duration: 0.5,
      opacity: 0,
      ease: 'power3.out',
      onComplete: () => {
        onClose();
      },
    });
  };

  useEffect(() => {
    const wrapper = menuWrapper;
    const container = containerRef.current;

    gsap.fromTo(
      wrapper,
      { x: 0, opacity: 1 },
      { x: -100, opacity: 0, duration: 0.6, ease: 'power3.out' }
    );

    gsap.fromTo(
      container,
      { x: 100, opacity: 0 },
      { x: 0, opacity: 1, duration: 0.6, ease: 'power3.out' }
    );

    return () => {};
  }, [menuWrapper]);

  return (
    <>
      <div ref={containerRef} className="pointer-events-auto absolute inset-0 opacity-0">
        <div className="absolute inset-0">
          <div className="absolute inset-0 bg-white"></div>
        </div>
        <div className="absolute inset-0 overflow-y-scroll">
          <div className="relative w-full">
            <div className="relative w-full">
              <div className="relative w-full">
                <ul className="relative w-full">
                  {subItems.map((item, index) => {
                    const { href } = resolveNavigationPageUrl(item);

                    return (
                      <NavigationSideMenuItem
                        key={item.id}
                        href={href}
                        useAsBackButton={index === 0}
                        useAsSecondaryLink={index !== 0}
                        onBack={onClickBackButton}
                      >
                        <Link {...item.link} />
                      </NavigationSideMenuItem>
                    );
                  })}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NavigationSideSecondaryMenu;
