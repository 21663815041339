interface FilterOnIconProps {
  className?: string;
}

const FilterOnIcon: React.FC<FilterOnIconProps> = (props) => {
  return (
    <>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={props.className}
      >
        <rect x="1" y="1" width="18" height="18" rx="9" stroke="currentColor" strokeWidth="2" />
        <rect x="4" y="4" width="12" height="12" rx="6" fill="currentColor" />
      </svg>
    </>
  );
};

export default FilterOnIcon;
